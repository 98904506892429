<template>
  <section
    :class="{active: store.state.isFixedheader}"
    class="containerFixHeader"
    ref="headerRef"
    :style="{'--baseWidthHeader': baseWidthHeader}">
    <div class="containerFixHeader__userInfo">
      <div class="containerFixHeader__userInfo--image">
        <img class="avatarFixHeader" :src="obtainProfilePicture" :alt="store.state.profileData.title">
      </div>
      <div class="containerFixHeader__userInfo--content">
        <div class="titleFixHeader">
          <p class="titleFixHeader__label">
            {{ store.state.profileData.title ? store.state.profileData.title : 'No name'}}
          </p>
          <optionsCard :id="store.state.profileData.id" @openCollectionsModal="openCollectionsModal" />
        </div>
        <div class="contentFixHeader">
          <profileLabels></profileLabels>
        </div>
      </div>
      <profileContactPills stickyHeader class="containerFixHeader__userInfo--pills"></profileContactPills>
    </div>
    <div class="containerFixHeader__buttons"></div>
  </section>
</template>

<script>
import { onMounted, computed, ref } from 'vue'
import { useStore } from 'vuex'
import optionsCard from '@/components/optionsCard/optionsCard'
import profileLabels from '@/components/profileLabels/profileLabels'
import profileContactPills from '@/components/profileContactPills/profileContactPills'

export default {
  name: 'fixHeader',
  props: {
    stickyStart: {
      type: Number,
      default: 350
    }
  },
  emits: ['openCollectionsModal'],
  components: {
    optionsCard,
    profileLabels,
    profileContactPills
  },
  setup (props, { emit }) {
    const baseWidthHeader = ref(0)
    const headerRef = ref(null)
    const store = useStore()

    onMounted(() => {
      setUpSticky()
    })

    /**
    * @description Obtain profile picture url or set static image
    */
    const obtainProfilePicture = computed(() => {
      if (!store.state.profileData.thumbnail_url) {
        return '/static/icons/persona-profile.svg'
      } else {
        return store.state.profileData.thumbnail_url
      }
    })

    /**
    * @description Set up Sticky behavior
    */
    function setUpSticky () {
      const containerAppRf = document.getElementById('containerApp')
      baseWidthHeader.value = `${containerAppRf.clientWidth}px`

      document.getElementById('containerApp').addEventListener('scroll', e => {
        const scrollDistance = e.target.scrollTop
        if (scrollDistance > props.stickyStart) {
          if (!store.state.isFixedheader) {
            store.commit({
              type: 'IS_FIXED_HEADER',
              flag: true
            })
          }
        } else {
          if (store.state.isFixedheader) {
            store.commit({
              type: 'IS_FIXED_HEADER',
              flag: false
            })
          }
        }
      })

      window.addEventListener('resize', (e) => {
        baseWidthHeader.value = `${containerAppRf.clientWidth}px`
      })
    }

    /**
    * @description Dispatch emit to open add to collections modal
    * @param itemsId From card
    * @param flag true or false to know if modal is open or close
    */
    function openCollectionsModal (itemsId, flag) {
      emit('openCollectionsModal', itemsId, flag)
    }

    return {
      store,
      headerRef,
      obtainProfilePicture,
      openCollectionsModal,
      baseWidthHeader
    }
  }
}
</script>
