<template>
  <genericModal title="Unsaved Changes"
                closeIcon="grey-icon-close"
                width="400px"
                height="230px"
                class="containerDiscardChangesModal"
                ref="modalRef"
                v-slot="genericModalProps">
    <span class="containerDiscardChangesModal__message">Do you want to discard them?</span>
    <section class="containerDiscardChangesModal__buttons">
      <button class="containerDiscardChangesModal__buttons--cancelButton" @click="genericModalProps.close" type="button">CANCEL</button>
      <button class="containerDiscardChangesModal__buttons--discardButton" type="button" @click="discardChanges">YES</button>
    </section>
  </genericModal>
</template>

<script>
import genericModal from '../genericModal/genericModal'
import { ref } from 'vue'

export default {
  name: 'discardChangesModal',
  components: {
    genericModal
  },
  emits: ['discard'],
  setup (props, { emit }) {
    const modalRef = ref(null)
    /**
     * @description Discards changes withous saving and close the modal.
     */
    function discardChanges () {
      modalRef.value.close()
      emit('discard')
    }

    /**
     * @description Opens the modal
     */
    function open () {
      modalRef.value.open()
    }

    return {
      modalRef,
      discardChanges,
      open
    }
  }
}
</script>
