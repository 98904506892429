<template>
  <section class="containerInputTree" v-click-away="showOptionsAsNotVisible" ref="dropdown" :key="keyToRefresh">
     <section class="containerInputTree__containerInput" :class="{'focusEnabled': focusEnabled}">
        <pill
          v-for="(item, index) in optionsSelected" :key="index"
          :label="item" class="containerInputTree__containerInput--pill"
          @remove="removePill(item)"
        />
        <input
          class="containerInputTree__containerInput--input"
          placeholder="Start typing..."
          v-model="searchTerm"
          @input="launchThrottledSearch"
          @focus="showOptionsAsVisible"
          @blur="focusEnabled = false"
        />
      </section>
      <section class="containerInputTree__containerSelectors" v-show="areOptionsVisible">
        <selectionTree v-for="(branch, index) in tree" :key="index" :field="branch" @changeSelection="updateSelectedOptions"/>
      </section>
  </section>
</template>

<script>
import selectionTree from '../selectionTree/selectionTree'
import pill from '../pill/pill'
import { onBeforeUpdate, onMounted, ref } from 'vue'

export default {
  name: 'inputTree',
  props: {
    tree: {
      type: Array,
      required: true
    },
    separator: {
      type: String,
      default: ' : '
    },
    modelValue: {}
  },
  components: {
    selectionTree,
    pill
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const areOptionsVisible = ref(false)
    const optionsSelected = ref([])
    const searchTerm = ref(null)
    const updated = ref(false)
    const throttleSearch = ref(null)
    const keyToRefresh = ref(1)
    const focusEnabled = ref(false)

    onMounted(() => {
      updateInitialPills()
    })

    onBeforeUpdate(() => {
      if (!updated.value) {
        updated.value = true
        updateSelectedOptions()
      }
    })

    /**
     * @description Launches a search of a term but throttled to avoid override the UX.
     */
    function launchThrottledSearch () {
      clearTimeout(throttleSearch.value)
      throttleSearch.value = setTimeout(() => filterTree(props.tree, searchTerm.value), 200)
    }

    /**
     * @description Updates the UI to show previous selected options.
     */
    function updateInitialPills () {
      setTimeout(() => {
        keyToRefresh.value = keyToRefresh.value + 1
      }, 0)
    }

    /**
     * @description Toggles visibility of options as visible.
     */
    function showOptionsAsVisible () {
      focusEnabled.value = true
      areOptionsVisible.value = true
      filterTree(props.tree, searchTerm.value)
    }

    /**
     * @description Toggles visibility of options as invisible.
     */
    function showOptionsAsNotVisible () {
      areOptionsVisible.value = false
      searchTerm.value = null
    }

    /**
     * @description Filters the options of a tree to only show those parents that match with a given term.
     * @param {array} array to iterate an search coincidences.
     * @param {term} string to be used as keyword.
     */
    function filterTree (array, term) {
      const cleanTerm = cleanString(term)
      if (Array.isArray(array)) {
        for (let index = 0; index < array.length; index++) {
          const element = array[index]
          element.isVisible = isASubstring(element.labelSearch, cleanTerm)
        }
        array = Object.assign({}, array)
      }
    }

    /**
     * @description Returns a flag to indicate if a string 'B' is a substring of string 'A'.
     * @param {stringA} string to chek if string 'B' forms part of it.
     * @param {stringB} string to check if is part of string 'A'.
     */
    function isASubstring (stringA, stringB) {
      const index = stringA.indexOf(stringB)
      return index > -1
    }

    /**
     * @description Removes blank spaces and parse the string to lower case to be compared.
     * @param {term} string to be cleaned.
     */
    function cleanString (term) {
      return !term ? '' : term.toLowerCase().trim()
    }

    /**
     * @description Updates the list of pills and the model based on the selections of the user.
     */
    function updateSelectedOptions () {
      const selectionLabels = []
      const pills = []

      props.tree.forEach(parent => {
        if (parent.isSelected) {
          if (!parent.isIndeterminate) {
            pills.push(parent.label)
          }

          parent.children.forEach(child => {
            if (child.isSelected) {
              const childLabel = `${parent.label}${props.separator}${child.label}`
              selectionLabels.push(childLabel)

              if (parent.isIndeterminate) {
                pills.push(childLabel)
              }
            }
          })
        }
      })

      optionsSelected.value = pills
      emit('update:modelValue', selectionLabels)
    }

    /**
     * @description Removes a pill from the list removing the selection of its linked checkbox.
     * @param {label} string label to be searched and deselected from the tree.
     */
    function removePill (label) {
      const labelSplitted = label.split(`${props.separator}`)
      const industrySelected = labelSplitted[0]
      const categorySelected = labelSplitted[1]

      if (!categorySelected) {
        props.tree.forEach((branch, index) => {
          if (branch.label === industrySelected) {
            branch.children.forEach((category, index) => {
              branch.children[index] = Object.assign({}, category, { isSelected: false })
            })
          }
        })
      } else {
        props.tree.forEach(branch => {
          branch.children.forEach((category, index) => {
            if (branch.label === industrySelected && category.label === categorySelected) {
              branch.children[index] = Object.assign({}, category, { isSelected: false })
            }
          })
        })
      }
    }

    return {
      launchThrottledSearch,
      showOptionsAsVisible,
      showOptionsAsNotVisible,
      removePill,
      optionsSelected,
      searchTerm,
      updateSelectedOptions,
      areOptionsVisible,
      keyToRefresh,
      focusEnabled
    }
  }
}
</script>
