<template>
  <section class="containerContactInfo">
    <transition name="slideInFromBottom">
      <section v-if="readyProfile" class="containerContactInfo__contactInfo">
        <section :class="[{'editExperienceMobileContainer customScrollbar': sections.organizational.isEditing && store.state.isPhone}]">
          <div class="containerContactInfo__contactInfo--profileHeading" :class="[{'editExperienceMobileHeading': sections.organizational.isEditing && store.state.isPhone}]">
            <span>ORGANIZATION</span>
            <span v-if="sections.organizational.isEditing && store.state.isPhone" class="editExperienceMobileIcon grey-icon-close" @click="toggleEditionMode('organizational', false)"></span>
            <span v-if="showEditIcon" class="grey-icon-edit editIcon" :class="{disabledIcon: sections.organizational.isEditing}" @click="toggleEditionMode('organizational', true)"></span>
          </div>
          <section
            class="containerContactInfo__contactInfo--additionalInformation"
            :class="[{'editing': sections.organizational.isEditing}, {'editExperienceMobileEditableContent': sections.organizational.isEditing && store.state.isPhone}]"
          >
            <section class="containerSectionContent" v-if="!sections.organizational.isEditing">
              <contactInformationField title="AGENCY" :field="profileData.agency[0]" fieldType="label"/>
              <contactInformationField title="DISCIPLINE" :field="profileData.discipline[0]" fieldType="label"/>
              <contactInformationField
                title="TITLE"
                :field="fieldPendingApprovalLabel('persona_title')"
                fieldType="label"
                :pendingApproval="isFieldPending('persona_title')"
                class="persona_title">
              </contactInformationField>
              <contactInformationField
                title="MANAGER"
                :field="fieldPendingApprovalLabel('employee_manager')"
                fieldType="link"
                :link="managerProfileUrl"
                :pendingApproval="isFieldPending('employee_manager')"
                :ecCode="fieldPendingApprovalLabel('employee_manager_employeecode')"
                :showToolTip="false">
              </contactInformationField>
              <contactInformationField title="REGION" :field="profileData.business_region[0]" fieldType="label"/>
              <contactInformationField title="COUNTRY" :field="profileData.country[0]" fieldType="label"/>
              <contactInformationField title="CITY" :field="profileData.city[0]" fieldType="label"/>
              <contactInformationField title="ADDRESS" :field="profileData.locations[0]" fieldType="label"/>
            </section>
            <section v-else>
              <contactInformationForm
                :id="profileData.id"
                :fields="sections.organizational.formData"
                @close="toggleEditionMode('organizational', false)" approvalFields
                @openDiscardChangesModal="openDiscardChangesModal"
                @openApiErrorModal="openApiErrorModal"
                @informTouchOnForm="activateUnsavedChanges"
                @formSaved="deactivateUnsavedChanges" />
            </section>
          </section>
        </section>
        <section :class="[{'editExperienceMobileContainer customScrollbar': sections.social.isEditing && store.state.isPhone}]">
          <div class="containerContactInfo__contactInfo--profileHeading" :class="[{'editExperienceMobileHeading': sections.social.isEditing && store.state.isPhone}]">
            <span>SOCIAL</span>
            <span v-if="sections.social.isEditing && store.state.isPhone" class="editExperienceMobileIcon grey-icon-close" @click="toggleEditionMode('social', false)"></span>
            <span v-if="showEditIcon" class="grey-icon-edit editIcon" :class="{disabledIcon: sections.social.isEditing}" @click="toggleEditionMode('social', true)"></span>
          </div>
          <section
            class="containerContactInfo__contactInfo--socialInformation"
            :class="[{'editing': sections.social.isEditing}, {'editExperienceMobileEditableContent': sections.social.isEditing && store.state.isPhone}]"
          >
            <section v-if="!sections.social.isEditing">
              <mediaIcon :link="profileData.linkedin" className="grey-icon-linkedin"/>
              <mediaIcon :link="profileData.instagram" className="grey-icon-instagram"/>
              <mediaIcon :link="profileData.twitter" className="grey-icon-twitter"/>
              <mediaIcon :link="profileData.facebook" className="grey-icon-facebook"/>
            </section>
            <section v-else>
              <contactInformationForm
                :id="profileData.id"
                :fields="sections.social.formData"
                @close="toggleEditionMode('social', false)"
                @openDiscardChangesModal="openDiscardChangesModal"
                @openApiErrorModal="openApiErrorModal"
                @informTouchOnForm="activateUnsavedChanges" />
            </section>
          </section>
        </section>
      </section>
    </transition>
  </section>
</template>

<script>
import contactInformationField from '../contactInformationField/contactInformationField'
import mediaIcon from '../mediaIcon/mediaIcon'
import contactInformationForm from '../contactInformationForm/contactInformationForm'
import { useStore } from 'vuex'
import { computed, onMounted, reactive, ref, watch } from 'vue'

export default {
  name: 'contactInformation',
  components: {
    contactInformationField,
    mediaIcon,
    contactInformationForm
  },
  props: {
    readyProfile: {
      type: Boolean,
      default: false
    },
    profileData: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    }
  },
  emits: ['openDiscardChangesModal', 'openApiErrorModal', 'handleUnsavedChangesListener', 'savedChanges'],
  setup (props, { emit }) {
    const store = useStore()
    const emptyField = ref('Not specified')
    const managerProfileUrl = ref(null)
    const sections = reactive({
      organizational: {
        isEditing: false,
        formData: {}
      },
      social: {
        isEditing: false,
        formData: {}
      }
    })

    onMounted(() => {
      getManagerData()
    })

    /**
     * @description Builds the url structure to chat with the person.
     */
    const teamsUrl = computed(() => {
      return `https://teams.microsoft.com/l/chat/0/0?users=${props.profileData.email}`
    })

    /**
     * @description Validate if edit icon should be displayed
     */
    const showEditIcon = computed(() => {
      return store.getters.isUserLogged(props.profileData.id) && store.getters.actionCanBePerformed('edit', 'profileInformation')
    })

    /**
     * @description Updates the visiibility of the form based on the user request.
     * @param {sectionName} string with the name of the section to be edited.
     */
    watch(() => store.state.sectionEdited, (sectionName) => {
      if (sectionName) {
        Object.keys(sections).forEach(section => {
          sections[section].isEditing = false
        })
        generateFormData(sectionName)

        if (sections[sectionName]) {
          sections[sectionName].isEditing = true
        }
      }
    })

    /**
     * @description Launches a state change related to a new edition request of a section has been launched.
     * @param {section} string with the name of section to be edited.
     * @param {isEditin} boolean to know if the section will be edited or not.
     */
    function toggleEditionMode (section, isEditing) {
      store.commit({
        type: 'SET_SECTION_EDITED',
        sectionEdited: isEditing ? section : ''
      })

      if (!isEditing) {
        sections[section].isEditing = false
      }
    }

    /**
     * @description Generates all the metadata to be displayed in the edition form of every section.
     * @param {section} string with the name of the section to be edited.
     */
    function generateFormData (section) {
      switch (section) {
        case 'organizational':
          sections.organizational.formData = [
            { label: 'AGENCY', disabled: true, model: props.profileData.agency[0], required: true, field: 'agency' },
            { label: 'DISCIPLINE', disabled: true, model: props.profileData.discipline[0], required: true, field: 'discipline' },
            { label: 'TITLE', disabled: false, model: fieldPendingApprovalLabel('persona_title'), required: true, field: 'persona_title' },
            { label: 'MANAGER', disabled: false, model: fieldPendingApprovalLabel('employee_manager'), required: true, field: 'employee_manager_employeecode', type: 'list', optionsPromise: getPeopleSuggestions, propertyToSend: 'eccode', defaultValue: props.profileData.employee_manager_employeecode },
            { label: 'REGION', disabled: true, model: props.profileData.business_region[0], required: false, field: 'business_region' },
            { label: 'COUNTRY', disabled: true, model: props.profileData.country[0], required: false, field: 'country' },
            { label: 'CITY', disabled: true, model: props.profileData.city[0], required: false, field: 'city' },
            { label: 'ADDRESS', disabled: true, model: props.profileData.locations[0], required: false, field: 'locations' }
          ]
          break
        case 'social':
          sections.social.formData = [
            { label: 'LINKEDIN', disabled: false, model: assignSocialMediaIfEmpty(props.profileData.linkedin, 'LINKEDIN'), required: false, field: 'linkedin', placeholder: 'https://linkedin.com/' },
            { label: 'INSTAGRAM', disabled: false, model: assignSocialMediaIfEmpty(props.profileData.instagram, 'INSTAGRAM'), required: false, field: 'instagram', placeholder: 'https://instagram.com/' },
            { label: 'TWITTER', disabled: false, model: assignSocialMediaIfEmpty(props.profileData.twitter, 'TWITTER'), required: false, field: 'twitter', placeholder: 'https://twitter.com/' },
            { label: 'FACEBOOK', disabled: false, model: assignSocialMediaIfEmpty(props.profileData.facebook, 'FACEBOOK'), required: false, field: 'facebook', placeholder: 'https://facebook.com/' }
          ]
          break
      }
    }

    /**
     * @description Data of manager
     */
    function getManagerData () {
      const approvalField = store.state.approvalFields.find(approvalField => approvalField.field === 'employee_manager_employeecode' && approvalField.status === 'pending')
      store.dispatch({
        type: 'getManagerData',
        ecCode: approvalField ? approvalField.value : props.profileData.employee_manager_employeecode
      })
    }

    /**
     * @description get label for fields pending approval.
     * @param fieldName the name of the field
     */
    function fieldPendingApprovalLabel (fieldName) {
      let fieldLable = ''
      let isFieldPendingApproval = null
      isFieldPendingApproval = fieldPendingApproval(fieldName)
      switch (fieldName) {
        case 'persona_title':
          fieldLable = isFieldPendingApproval || props.profileData.persona_title
          break
        case 'employee_manager':
          fieldLable = isFieldPendingApproval || props.profileData.employee_manager
          break
        case 'employee_manager_employeecode':
          fieldLable = isFieldPendingApproval || props.profileData.employee_manager_employeecode
          managerProfileUrl.value = `https://${window.location.host}/profile/${fieldLable}`
          break
      }
      return fieldLable
    }

    /**
     * @description Validate if a field is waiting for an approval.
     * @param fieldName the name of the field to be verified
     */
    function fieldPendingApproval (fieldName) {
      const field = store.state.approvalFields.find(approvalField => approvalField.field === fieldName)
      return store.getters.isUserLogged(props.profileData.id) ? field && field.value : ''
    }

    /**
     * @description Indicates if a field is waiting for approval.
     * @param fieldName string with the name of the field.
     */
    function isFieldPending (fieldName) {
      return store.getters.isUserLogged(props.profileData.id) ? store.state.approvalFields.some(approvalField => approvalField.field === fieldName) : false
    }

    /**
     * @description Returns a promise to be launched when needed search a suggestion for users' name.
     * @param term string term to make the search.
     */
    function getPeopleSuggestions (term) {
      return store.dispatch({
        type: 'getPeopleSuggestions',
        term: term
      }).then(options => {
        if (Array.isArray(options)) {
          return options.map(option => {
            return { eccode: option.eccode, label: option.name }
          })
        }
      })
    }

    /**
     * @description Build a base url for social media if user does not have any of them.
     * @param {socialModel} Social media model
     * @param {social} Social media label
     */
    function assignSocialMediaIfEmpty (socialModel, social) {
      let urlBase = 'https://'
      switch (social) {
        case 'LINKEDIN':
          urlBase += 'linkedin.com/'
          break
        case 'INSTAGRAM':
          urlBase += 'instagram.com/'
          break
        case 'TWITTER':
          urlBase += 'twitter.com/'
          break
        case 'FACEBOOK':
          urlBase += 'facebook.com/'
          break
      }
      return !socialModel ? urlBase : socialModel
    }

    /**
     * @description open discard changes modal
     */
    function openDiscardChangesModal () {
      emit('openDiscardChangesModal')
    }

    /**
     * @description open api error modal
     */
    function openApiErrorModal () {
      emit('openApiErrorModal')
    }

    /**
      * @description flag indicating unsaved changes in one of the editable fields.
      * @param {Event} event of input change fired by user.
      */
    function activateUnsavedChanges (event) {
      emit('handleUnsavedChangesListener')
      if (!store.state.formIsTouched) {
        store.commit({
          type: 'SET_FORM_TOUCHED',
          formIsTouched: true
        })
      }
    }

    /**
      * @description flag indicating unsaved changes in one of the editable fields.
      * @param {Event} event of input change fired by user.
      */
    function deactivateUnsavedChanges (event) {
      emit('savedChanges')
      if (store.state.formIsTouched) {
        store.commit({
          type: 'SET_FORM_TOUCHED',
          formIsTouched: false
        })
      }
    }

    return {
      store,
      emptyField,
      managerProfileUrl,
      showEditIcon,
      toggleEditionMode,
      fieldPendingApproval,
      isFieldPending,
      openDiscardChangesModal,
      openApiErrorModal,
      sections,
      fieldPendingApprovalLabel,
      teamsUrl,
      assignSocialMediaIfEmpty,
      activateUnsavedChanges,
      deactivateUnsavedChanges
    }
  }
}
</script>
