<template>
  <section class="containerSuccessProcess">
    <section class="containerSuccessProcess__messages">
      <p class="containerSuccessProcess__messages--label1" v-if="label1">{{label1}}</p>
      <p class="containerSuccessProcess__messages--label2" v-if="label2">{{label2}}</p>
      <p class="containerSuccessProcess__messages--label3" v-if="label3">{{label3}}</p>
    </section>
    <section class="containerSuccessProcess__buttons">
      <button class="containerSuccessProcess__buttons--ok" @click="endProcess">OK</button>
    </section>
  </section>
</template>

<script>

export default {
  name: 'successProcess',
  props: {
    label1: {
      type: String,
      required: true
    },
    label2: {
      type: String,
      required: false,
      default: ''
    },
    label3: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: ['uploadProcessFinish'],
  setup (props, { emit }) {
    /**
     * @description End flow and close modals opened
     */
    function endProcess () {
      emit('uploadProcessFinish')
    }
    return {
      endProcess
    }
  }
}
</script>
