<template>
  <div class="profileLabels">
    <p class="profileLabels__label">
      <span class="profileLabels__label--icon grey-icon-briefcase"></span>
      <span class="profileLabels__label--txt">
        {{ store.state.profileData.persona_title ? store.state.profileData.persona_title : 'No title'}}
      </span>
    </p>
    <p class="profileLabels__label">
      <span class="profileLabels__label--icon grey-icon-location"></span>
      <span class="profileLabels__label--txt">
        {{ store.state.profileData.country[0] ? store.state.profileData.country[0] : 'No country' }}
      </span>
    </p>
    <p class="profileLabels__label">
      <span class="profileLabels__label--icon grey-icon-agency"></span>
      <span class="profileLabels__label--txt">
        {{ store.state.profileData.agency[0] ? store.state.profileData.agency[0] : 'No agency' }}
      </span>
    </p>
  </div>
</template>

<script>
import { useStore } from 'vuex'

export default {
  name: 'profileLabels',
  setup (props) {
    const store = useStore()
    return {
      store
    }
  }
}
</script>
