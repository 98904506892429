<template>
  <section class="containerUploadImage">
    <header class="containerUploadImage__header">
      <span>Please Upload or Drag and Drop a Photo</span>
    </header>
    <section class="containerUploadImage__body" :class="{active: hoverIsActive}" @click="openFileSelector">
      <input type="file" ref="uploaderInput" hidden @change="inputChangeHandler()" accept="image/x-png, image/jpeg, image/jpg"/>
      <span
        class="grey-icon-people-placeholder containerUploadImage__body--icon"
        ref="uploader"
      >
      </span>
    </section>
    <section class="containerUploadImage__rules">
      <span class="containerUploadImage__rules--error" v-if="errorMessage"> {{errorMessage}} </span>
      <span class="containerUploadImage__rules--minimunSize">Image resolution : 400px (Min)</span>
      <span class="containerUploadImage__rules--recommended">Maximum Size 76 MB</span>
    </section>
  </section>
</template>

<script>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'uploadImage',
  emits: ['upload'],
  setup (props, { emit }) {
    const store = useStore()
    const hoverIsActive = ref(false)
    const counterHover = ref(0)
    const errorMessage = ref('')
    const uploaderInput = ref(null)
    const uploader = ref(null)

    onMounted(() => {
      addDragAndDropListeners()
    })

    /**
     * @description Emulates a click action of the user to open a window to select a file to upload.
     */
    function openFileSelector () {
      uploaderInput.value.click()
    }

    /**
     * @description Add listeners to manage drag and drop functionality
     */
    function addDragAndDropListeners () {
      uploader.value.addEventListener('dragenter', addHoverClass, false)
      uploader.value.addEventListener('dragover', stopEvent, false)
      uploader.value.addEventListener('dragleave', removeHoverClass, false)
      uploader.value.addEventListener('drop', dropHandler, false)
    }

    /**
     * @description Adds class with stiles of hover on drag and drop area.
     * @param event event fired by user.
     */
    function addHoverClass (event) {
      toggleHoverClass(event, true)
    }

    /**
     * @description Removes class with stiles of hover on drag and drop area.
     * @param event event fired by user.
     */
    function removeHoverClass (event) {
      toggleHoverClass(event, false)
    }

    /**
     * @description Toggles precence of hover class to element based on user interaction.
     * @param event event fired by user.
     * @param isClassPresent flag to indicate if hover class should be applied or not.
     */
    function toggleHoverClass (event, isClassPresent) {
      stopEvent(event)
      if (isClassPresent) {
        hoverIsActive.value = true
        counterHover.value++
      } else {
        counterHover.value--
        if (counterHover.value === 0) {
          hoverIsActive.value = false
        }
      }
    }

    /**
     * @description Stops propagation of a given event.
     * @param {event} event to stop its propagation.
     */
    function stopEvent (event) {
      event.preventDefault()
      event.stopPropagation()
    }

    /**
     * @description Manages the drop event to get the file and start to upload process.
     * @param {event} event launches by user's drop action.
     */
    function dropHandler (event) {
      stopEvent(event)
      hoverIsActive.value = false
      if (event.dataTransfer.files[0].type.indexOf('image/gif') === 0 || event.dataTransfer.files[0].type.indexOf('image') === -1) {
        errorMessage.value = 'File type not supported, Please try again with an image'
      } else {
        inputChangeHandler(event.dataTransfer.files[0])
      }
    }

    /**
     * @description Manipulate file uploaded
     * Read file uploaded, if It is image, read image and get dimensions
     * @param File updated
     * @
     */
    function inputChangeHandler (File) {
      /* eslint-disable */
      const file = File ? File : uploaderInput.value.files[0]
      /* eslint-enable */
      const sizeMBImage = parseFloat(((file.size / 1024) / 1024).toFixed(1))
      const reader = new FileReader()
      const image = new Image()

      readImage(file, reader, image).then(
        () => {
          getImage(image).then(
            (width, height) => {
              if (width < 400) {
                errorMessage.value = 'Image is less than 400px width, please try again.'
                return
              }

              if (sizeMBImage > 76) {
                errorMessage.value = 'Maximum size is bigger than allowed, please try again.'
                return
              }
              store.commit({
                type: 'SET_ORIGINAL_PROFILE_IMAGE',
                originalProfilePicture: file
              })
              emit('upload', image)
            }
          )
        }
      )
    }

    /**
     * @description Read File (Image)
     * @param file uploaded
     * @param reader object of JS
     * @param image object of JS
     * @returns {Promise} resolve promise
     */
    function readImage (file, reader, image) {
      return new Promise((resolve, reject) => {
        reader.onloadend = (e) => {
          image.src = reader.result
          resolve()
        }
        reader.readAsDataURL(file)
      })
    }

    /**
     * @description Get dimensions of image
     * @param image object of JS
     * @returns {Promise} resolve promise
     */
    function getImage (image) {
      return new Promise((resolve, reject) => {
        image.onload = () => {
          const { width, height } = image
          resolve(width, height)
        }
      })
    }

    return {
      store,
      addDragAndDropListeners,
      hoverIsActive,
      counterHover,
      errorMessage,
      uploaderInput,
      uploader,
      openFileSelector,
      inputChangeHandler
    }
  }
}
</script>
