<template>
  <section class="containerProfileContactPill" :class="[{'profilePillDisabled': pillDisabled, 'pillActive' : pillActive}, {'stickyHeader': stickyHeader}]" @click="contactPillAction">
    <span :class="[icon, pillActive]" class="containerProfileContactPill__icon"></span>
    <section :title="title">
      <span class="containerProfileContactPill__label">{{fieldValue}}</span>
      <span :class="[multipleModal ? '' : 'grey-icon-edit']" class="containerProfileContactPill__icon" v-if="editable && store.getters.isUserLogged()"></span>
    </section>
    <contactPillModal
      v-if="displayContactPillModal && !multipleModal"
      :propertyToUpdate="propertyToUpdate"
      :modelValue="store.state.profileData.mobile_phone"
      @closeContactPillModal="closeContactPillModal"
      @notLoggedUserAction="notLoggedUserAction"
      :resolutionWhenClick="resolutionWhenClick"
      @pillIsTouch="activateUnsavedChanges"
    >
    </contactPillModal>
    <contactPillModalMultiple
      :showDisclaimer="true"
      v-if="displayContactPillModal && multipleModal"
      @pillIsTouch="activateUnsavedChanges"
      @closeContactPillModalMultiple="closeContactPillModal"
      :pillsLst="getRecoveryPillsInfo"
    />
  </section>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import contactPillModal from '@/components/contactPillModal/contactPillModal'
import contactPillModalMultiple from '@/components/contactPillModalMultiple/contactPillModalMultiple'

export default {
  name: 'profileContactPill',
  props: {
    propertyToUpdate: {
      type: String,
      required: false
    },
    field: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    stickyHeader: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: false
    },
    multipleModal: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: false
    }
  },
  components: {
    contactPillModal,
    contactPillModalMultiple
  },
  emits: ['contactPillAction'],
  setup (props, { emit }) {
    const store = useStore()
    const displayContactPillModal = ref(false)
    const resolutionWhenClick = ref(null)
    const pillActive = ref('')

    /**
     * @description Gets the field value label
     */
    const fieldValue = computed(() => {
      return props.field ? props.field : 'Not specified'
    })

    /**
     * @description Validate if pill is disabled
     */
    const pillDisabled = computed(() => {
      return props.field ? (store.getters.isUserLogged() ? (props.stickyHeader ? true : !props.editable) : false) : (store.getters.isUserLogged() ? (props.stickyHeader ? true : !props.editable) : true)
    })

    /**
     * @description returns array for recovery information pills
     */
    const getRecoveryPillsInfo = computed(() => {
      return props.multipleModal ? [
        {
          icon: 'grey-icon-recovery-phone',
          propertyToUpdate: 'recoveryPhone',
          modelValue: store.state.recoveryInfo.recoveryPhone,
          title: 'Recovery Phone Number'
        },
        {
          icon: 'grey-icon-recovery-email',
          propertyToUpdate: 'recoveryEmail',
          modelValue: store.state.recoveryInfo.recoveryEmail,
          title: 'Recovery Email'
        }
      ] : []
    })

    /**
     * @description Contact pill action handler
     */
    function contactPillAction () {
      if (props.editable) {
        if (store.getters.isUserLogged()) {
          if (!props.stickyHeader) {
            resolutionWhenClick.value = window.innerWidth
            pillActive.value = 'pillActive'
            displayContactPillModal.value = true
          }
        } else {
          if (props.stickyHeader) {
            emit('contactPillAction')
          } else {
            displayContactPillModal.value = true
          }
        }
      } else {
        emit('contactPillAction')
      }
    }

    /**
     * @description Not logged user action handler
     */
    function notLoggedUserAction () {
      displayContactPillModal.value = false
      emit('contactPillAction')
    }

    /**
     * @description Close modal edit and reste a couple of properties.
     */
    function closeContactPillModal () {
      displayContactPillModal.value = false
      pillActive.value = ''
      manageBeforeUnloadListener(false)
      if (!store.state.sectionEdited) {
        store.commit({
          type: 'SET_FORM_TOUCHED',
          formIsTouched: false
        })
      }
    }

    /**
     * @description Set formIsTouch in true and addEventListener
     */
    function activateUnsavedChanges () {
      manageBeforeUnloadListener(true)
      if (!store.state.formIsTouched) {
        store.commit({
          type: 'SET_FORM_TOUCHED',
          formIsTouched: true
        })
      }
    }

    /**
     * @description Adds/Removes listener for beforeunload event.
     * @param {boolean} addEvent Flag to know if listener should be added or removed.
     */
    function manageBeforeUnloadListener (addEvent = true) {
      window[addEvent ? 'addEventListener' : 'removeEventListener']('beforeunload', listenerChangePage)
    }

    /**
     * @description Listens when the users wants to change the page and shows a prevent modal.
     * @param {event} event fired by the user.
     */
    function listenerChangePage (event) {
      event.preventDefault()
      event.returnValue = ''
    }

    return {
      store,
      displayContactPillModal,
      fieldValue,
      pillDisabled,
      contactPillAction,
      notLoggedUserAction,
      resolutionWhenClick,
      pillActive,
      closeContactPillModal,
      activateUnsavedChanges,
      getRecoveryPillsInfo
    }
  }
}
</script>
