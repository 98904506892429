<template>
  <section class="containerSettings" id="settings" :class="{'mobile': mobile}">
    <header class="containerSettings__header">
      <div class="containerSettings__header--iconLabel">
        <span class="title">Settings</span>
        <span class="icon-grey-close close" @click="closeSettings"></span>
      </div>
    </header>
    <first-page v-if="defaultView" :defaultView="defaultView"></first-page>
  </section>
</template>

<script>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import firstPage from './firstPage/firstPage'

export default {
  name: 'settings',
  components: {
    firstPage
  },
  props: {
    mobile: {
      type: Boolean,
      required: false
    },
    ecCode: {
      type: String,
      required: true
    }
  },
  emits: ['close-settings-menu'],
  setup (props, { emit }) {
    const store = useStore()
    const defaultView = ref('')

    onMounted(() => {
      getPreferences()
    })

    /**
     * @description Get preferences for profile
     */
    function getPreferences () {
      store.dispatch({
        type: 'getPreferences'
      }).then((response) => {
        defaultView.value = response.data.defaultView
      })
    }

    /**
     * @description Launch emit to close menu
     */
    function closeSettings () {
      emit('close-settings-menu')
    }

    return {
      store,
      closeSettings,
      defaultView
    }
  }
}
</script>
