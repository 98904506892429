<template>
  <section class="containerMyPortfolio"
    :class="{'noPortfolioItems': !isLoading && !data.length}"
    ref="containerMyPortfolio"
  >
    <template v-if="!isLoading">
      <section class="containerMyPortfolio__noItemsMessage" v-if="!isLoading && !data.length">
        <div class="containerMyPortfolio__noItemsMessage--messageContent">
          <img class="messageImg" :src="require('@/assets/empty-portfolio-icon.svg')">
          <span class="messageText">{{messages.noItemsMessage}}</span>
        </div>
      </section>
      <ul class="containerMyPortfolio__list" :class="{'dragAndDropEnabled': store.state.dragAndDropVisible && store.state.dragAndDropEnabled}">
        <li class="containerMyPortfolio__list--item"
          :id="`draggable${index}`"
          v-for="(item, index) in data" :key="index"
          :style="portfolioCardStyles(item)"
          :class="{'dragAndDropEnabled': store.state.dragAndDropVisible && store.state.dragAndDropEnabled}"
          :draggable="store.state.dragAndDropVisible && store.state.dragAndDropEnabled"
          @dragstart="dragStart($event, index)"
          @drag="drag($event)"
          @dragend="dragEnd($event, index)"
          @drop.prevent
          @dragenter="dragEnter($event, index)"
          @dragover.prevent
          @dragleave.prevent
        >
          <span class="grey-icon-videocamera" v-if="isVideoType(item)"></span>
          <div class="additionalInfoContainer"
            @click="goToDetail(item.source, item.itemId, index)"
            :class="{'visible': indexOver === index}"
            @mouseover="indexOver = index"
            @mouseleave="resetValues">
            <header class="additionalInfoContainer__header">
              <span class="additionalInfoContainer__header--source">{{ filterSourceName(item.source) }}</span>
              <span class="additionalInfoContainer__header--title box-vertical">{{item.title}}</span>
            </header>
            <section class="additionalInfoContainer__socialInfo">
              <span class="icon-grey-delete additionalInfoContainer__delete--icon"
                v-if="isMyProfile && !store.state.dragAndDropEnabled"
                @click.prevent.stop="showDeleteConfirmationModal(item)">
                <span class="label">Remove</span>
              </span>
              <span class="grey-icon-heart additionalInfoContainer__socialInfo--icon"
                v-if="messages.socialToShow[item.source].likes">
                <span class="counter">{{ isNumberNull(item.likes) }}</span>
              </span>
              <span class="grey-icon-views additionalInfoContainer__socialInfo--icon"
                v-if="messages.socialToShow[item.source].views">
                <span class="counter">{{ isNumberNull(item.views) }}</span>
              </span>
              <span class="grey-icon-comments additionalInfoContainer__socialInfo--icon"
                v-if="messages.socialToShow[item.source].comments">
                <span class="counter">{{ isNumberNull(item.comments) }}</span>
              </span>
            </section>
          </div>
        </li>
      </ul>
    </template>
    <skeletonMyPortfolio v-else></skeletonMyPortfolio>
  </section>
</template>

<script>
import { computed, onBeforeUnmount, onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import messagesConfig from './myPortfolio.json'
import { useRoute } from 'vue-router'
import skeletonMyPortfolio from '@/components/skeletons/skeletonMyPortfolio/skeletonMyPortfolio'

export default {
  name: 'myPortfolio',
  props: {
    isMyProfile: {
      type: Boolean,
      default: false
    }
  },
  components: {
    skeletonMyPortfolio
  },
  emits: ['setReorderDisabled', 'openDeleteConfirmationModal'],
  setup (props, { emit }) {
    const store = useStore()
    const route = useRoute()
    const messages = reactive(messagesConfig)
    const data = ref([])
    const isLoading = ref(true)
    const currentCard = ref(null)
    const dragging = ref(-1)
    const indexOver = ref(null)
    const handleObject = ref(null)
    const dragClickOffsetX = ref(0)
    const dragClickOffsetY = ref(0)
    const lastDragX = ref(0)
    const lastDragY = ref(0)

    onMounted(() => {
      setup()
    })

    onBeforeUnmount(() => {
      isLoading.value = null
    })

    /**
     * @description Set card backgroun image
     */
    const portfolioCardStyles = computed(() => (item) => {
      return `--background-image: url("${item.thumbnail_url}")`
    })

    /**
     * @description returns a flag to know if the item is a video.
     */
    const isVideoType = computed(() => (item) => {
      return item.file_type && messages.videoFileTypes.some(fileType => fileType === item.file_type.toLowerCase())
    })

    /**
     * @description Set a default counter if it is being null
     * @param value Counter
     */
    const isNumberNull = computed(() => (value) => {
      return value === null ? 0 : value
    })

    /**
     * @description Re name source using a filter (It will keeping the original value from server.)
     * @param source Card soruce
     */
    const filterSourceName = computed(() => (source) => {
      return source === 'sharepoint' ? 'Office 365' : source === 'culturedaily' ? 'culture daily' : source === 'gogrey' ? 'Go Grey News' : source
    })

    /**
     * @description Initialize needed state
     */
    function setup () {
      store.commit({
        type: 'TOGGLE_WALKTHROUGH_VISIBILITY',
        walkthroughName: 'PPRO_myPortfolio',
        property: 'isReadyToRender',
        value: true
      })
      store.dispatch({
        type: 'getPortfolioList',
        ecCode: route.params.id,
        fields: messages.fieldsPortfolio
      }).then(
        (response) => {
          data.value = response
          data.value.forEach((portfolioItem) => {
            portfolioItem.thumbnail_url = portfolioItem.previews.length
              ? portfolioItem.previews[0]
              : (portfolioItem.thumbnail_url ? portfolioItem.thumbnail_url : searchDefaultThumbnail(portfolioItem.extension))
          })
          isLoading.value = false
          emit('setReorderDisabled', !data.value.length)
        }
      )
    }

    /**
     * @description Set placeholder if there is not thumbnail
     * @param fileType of file
     */
    function searchDefaultThumbnail (fileType) {
      const image = messages.defaultThumbnailsTypes.find(item => item.extensions.some(ext => ext === fileType))
      if (image) {
        return require(`@/assets/placeholders/${image.defaultImage}`)
      }
      return require('@/assets/placeholders/thumb_others.svg')
    }

    /**
     * @description Go to detail page from the card
     * @param source of app
     * @param cardId Id of card to redirect to the detail
     * @param index of item
     */
    function goToDetail (source, cardId, index) {
      let baseUrl = ''
      let detailPage = ''

      if (navigator.userAgent.match(/Android|iPhone|iPad/i)) {
        if (currentCard.value !== index) {
          currentCard.value = index
          return
        }
      }

      switch (source) {
        case 'news':
          detailPage = `detailNews/${cardId}`
          break
        case 'intelligence':
          source = 'search'
          detailPage = `detail/${cardId}`
          break
        case 'work':
          source = 'search'
          detailPage = `detailWork/${cardId}`
          break
        case 'culturedaily':
        case 'sharepoint':
          window.open(data.value[index].src_url)
          break
        case 'gogrey':
          source = 'news'
          detailPage = `detailNews/${cardId}`
          break
      }
      if (detailPage) {
        const env = process.env.VUE_APP_API_ENV === 'prd' ? '' : `${process.env.VUE_APP_API_ENV}-`
        baseUrl = `https://${env}${source}.grey.com/${detailPage}`
        window.open(baseUrl, '_self')
      }
    }

    /**
     * @description Reset variable to initial state when leave event has been launched
     */
    function resetValues () {
      currentCard.value = null
      indexOver.value = null
    }

    /**
     * @description Opens modal to confirm the item deletion.
     * @param {item} item to be deleted.
     */
    function showDeleteConfirmationModal (item) {
      emit('openDeleteConfirmationModal', item)
    }

    /**
     * @description Deletes the item from portfolio.
     * @param {item} item to be deleted.
     */
    function removeItem (item) {
      store.dispatch({
        type: 'deleteItemFromPortfolio',
        id: item.itemId
      }).then(() => {
        data.value = data.value.filter(itemOnPortfolio => itemOnPortfolio.itemId !== item.itemId)
        emit('setReorderDisabled', !data.value.length)
      })
    }

    /**
     * @description action when we start dragging a draggable element
     * @param event DOM event
     * @param index index of the element
     */
    function dragStart (event, index) {
      if (!store.state.dragAndDropVisible || !store.state.dragAndDropEnabled) return
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.effectAllowed = 'move'
      const draggedObject = event.target
      handleObject.value = draggedObject.cloneNode(true)
      draggedObject.parentNode.insertBefore(handleObject.value, draggedObject)
      styleHandle(draggedObject.offsetWidth, draggedObject.offsetHeight)
      dragging.value = index
      dragClickOffsetX.value = event.layerX
      dragClickOffsetY.value = event.layerY
      document.querySelector(`#draggable${index}`).classList.add('opacityCero')
    }

    /**
     * @description Initial styles for cloned object
     * @param width original object width
     * @param height original object height
     */
    function styleHandle (width, height) {
      handleObject.value.id = 'cloneObject'
      handleObject.value.style.position = 'fixed'
      handleObject.value.style.zIndex = 9999
      handleObject.value.style.width = width + 'px'
      handleObject.value.style.height = height + 'px'
      handleObject.value.style.left = '-9999px'
      handleObject.value.style.border = '3px solid #F54029'
      handleObject.value.style['pointer-events'] = 'none'
      handleObject.value.style['border-radius'] = '11px'
      handleObject.value.style.margin = 0
      handleObject.value.style.padding = 0
    }

    /**
     * @description action when we drag a draggable element
     * @param event DOM event
     */
    function drag (event) {
      if (!store.state.dragAndDropVisible || !store.state.dragAndDropEnabled) return
      let useX = event.x
      let useY = event.y
      if (useX === 0 && useY === 0) {
        useX = lastDragX.value
        useY = lastDragY.value
      }
      if (useX === lastDragX.value && useY === lastDragY.value) {
        return
      }
      translate(useX - dragClickOffsetX.value, useY - dragClickOffsetY.value)
      lastDragX.value = useX
      lastDragY.value = useY

      const rect = handleObject.value.getBoundingClientRect()
      const bottomSpace = window.innerHeight - rect.bottom
      if (bottomSpace < 20) {
        document.querySelector('#app').scrollTop += 20
      }
      if (rect.top < 20) {
        document.querySelector('#app').scrollTop -= 20
      }
    }

    /**
     * @description Translate cloned object with mouse moving
     * @param x x position to locate cloned object
     * @param y y position to locate cloned object
     */
    function translate (x, y) {
      handleObject.value.style.left = x + 'px'
      handleObject.value.style.top = y + 'px'
    }

    /**
     * @description action when we end dragging a draggable element
     * @param event DOM event
     * @param indexTo index of the element
     */
    function dragEnd (event, indexTo) {
      if (!store.state.dragAndDropVisible || !store.state.dragAndDropEnabled) return
      document.querySelector(`#draggable${indexTo}`).classList.remove('opacityCero')
      document.querySelector(`#draggable${dragging.value}`).classList.remove('opacityCero')
      if (indexTo !== dragging.value) {
        const orderedItems = data.value.map(item => item.itemId)
        store.dispatch({
          type: 'orderPortfolioItems',
          items: { items: orderedItems }
        })
      }
      handleObject.value.parentNode.removeChild(handleObject.value)
      handleObject.value = null
      dragging.value = -1
    }

    /**
     * @description action when we enter with a draggable element on a droppable area
     * @param event DOM event
     * @param indexTo index of the element
     */
    function dragEnter (event, indexTo) {
      if (!store.state.dragAndDropVisible || !store.state.dragAndDropEnabled) return
      data.value.splice(indexTo, 0, data.value.splice(dragging.value, 1)[0])
      if (indexTo !== dragging.value) {
        document.querySelector(`#draggable${indexTo}`).classList.add('opacityCero')
        document.querySelector(`#draggable${dragging.value}`).classList.remove('opacityCero')
      }
      indexOver.value = indexTo
      dragging.value = indexTo
    }

    return {
      store,
      route,
      portfolioCardStyles,
      isVideoType,
      isNumberNull,
      goToDetail,
      resetValues,
      showDeleteConfirmationModal,
      removeItem,
      dragStart,
      drag,
      dragEnd,
      dragEnter,
      data,
      messages,
      indexOver,
      filterSourceName,
      isLoading
    }
  }
}
</script>
