<template>
  <div class="skeletonMyPortfolioContainer">
      <div class="skeletonMyPortfolioContainer__item" v-for="index in items" :key="index"></div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'skeletonMyPortfolio',
  setup () {
    const items = ref(8)

    return {
      items
    }
  }
}
</script>
