<template>
  <section class="containerContactPillModal"
    :class="{'editionEnabled': enableEdition, 'containerContactPillModal--recoveryPhone' : mobileNumberInput}"
    v-click-away="clickOutside"
    @click="clickStep1Handler($event)"
  >
    <div class="containerContactPillModal__header">
      <span :class="modalIcon" class="containerContactPillModal__header--icon"></span>
      <span class="containerContactPillModal__header--label">{{ modalTitle || modalLabel }}</span>
    </div>
    <section class="containerContactPillModal__content" v-if="enableEdition">
      <input
        v-model="pillModalValue"
        class="containerContactPillModal__content--input"
        :class="[requiredValidationActive || emailValidationError ? 'requiredValidation' : '', mobileNumberInput ? 'mobileNumberInput' : '', emailValidationError ? 'emailValidationError' : '']"
        :maxlength="mobileNumberInput ? 15 : 254"
        @keypress="validateKeyPress($event)"
        @keydown="validateKeyDown"
        @keyup="validateKeyUp"
        @paste="inputPaste($event)"
        @input="informPillIsTouch"
      />
      <span
        class="containerContactPillModal__content--message"
        v-if="mobileNumberInput">Enter your country code, area code, and phone number. Only numbers are allowed</span>
      <span
        class="containerContactPillModal__content--message containerContactPillModal__content--messageEmailValidation"
        v-if="emailValidationError">This must be different to a corporate email</span>
      <button
        @click="savePillValue"
        class="containerContactPillModal__content--save"
        :class="{'pillModalButtonDisabled': store.state.savingProfile}"
        type="button">
        <spinner class="spinnerButton" v-if="store.state.savingProfile"/>
        <span v-else>SAVE</span>
      </button>
    </section>
    <span class="containerContactPillModal__arrowDown"></span>
  </section>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import spinner from '@/components/spinner/spinner'

export default {
  name: 'contactPillModal',
  components: {
    spinner
  },
  props: {
    modelValue: {
      type: String,
      required: true
    },
    propertyToUpdate: {
      type: String,
      required: true
    },
    resolutionWhenClick: {
      type: Number,
      required: false
    },
    modalTitle: {
      type: String,
      required: false
    }
  },
  emits: ['closeContactPillModal', 'notLoggedUserAction', 'pillIsTouch'],
  setup (props, { emit }) {
    const store = useStore()
    const enableEdition = ref(false)
    const pillModalValue = ref('')
    const keyDownValue = ref('')
    const emailValidationError = ref(false)

    onMounted(() => {
      pillModalValue.value = props.modelValue
      enableEdition.value = store.getters.isUserLogged() ? !showMobileVersion.value : false
    })

    /**
     * @description Set modal icon
     */
    const modalIcon = computed(() => {
      return store.getters.isUserLogged() ? 'grey-icon-recovery-edit edit-icon' : 'grey-icon-profile-mobile'
    })

    /**
     * @description Set modal label
     */
    const modalLabel = computed(() => {
      return enableEdition.value ? 'Mobile Phone Number' : store.getters.isUserLogged() ? 'Edit' : 'Call'
    })

    /**
     * @description Display conditional validation class
     */
    const requiredValidationActive = computed(() => {
      return mobileNumberInput.value ? (!pillModalValue.value || !/^[0-9]+$/.test(pillModalValue.value)) : !validateEmail()
    })

    /**
     * @description Set property Bool depending resolution.
     */
    const showMobileVersion = computed(() => {
      return props.resolutionWhenClick <= 768
    })

    /**
     * @description Validate if single input must be displayed.
     */
    const mobileNumberInput = computed(() => {
      return ['mobile_phone', 'recoveryPhone'].includes(props.propertyToUpdate)
    })

    /**
     * @description Hide menu whenever user click outside of component
     */
    function clickOutside () {
      if (!store.state.savingProfile) {
        emit('closeContactPillModal')
      }
    }

    /**
     * @description Click on modal step1 handler
     * @param event DOM event
     */
    function clickStep1Handler (event) {
      event.preventDefault()
      event.stopPropagation()
      if (store.getters.isUserLogged()) {
        enableEdition.value = true
      } else {
        emit('notLoggedUserAction')
      }
    }

    /**
     * @description Save edited pill value
     */
    function savePillValue () {
      if (props.propertyToUpdate.includes('recovery')) {
        if (props.propertyToUpdate.includes('recoveryEmail') && (/@grey.com\s*$/.test(pillModalValue.value.toLowerCase()) || pillModalValue.value.toLowerCase() === store.state.profileData.email.toLowerCase())) {
          emailValidationError.value = true
        } else {
          emailValidationError.value = false
          if (!requiredValidationActive.value) {
            const body = props.propertyToUpdate.includes('recoveryEmail')
              ? { recoveryPhone: store.state.recoveryInfo.recoveryPhone, recoveryEmail: pillModalValue.value }
              : { recoveryPhone: pillModalValue.value, recoveryEmail: store.state.recoveryInfo.recoveryEmail }
            store.dispatch({
              type: 'saveRecoveryInfo',
              body: body
            }).then((response) => {
              store.commit({
                type: 'SET_RECOVERY_INFO',
                recoveryInfo: { recoveryPhone: response.data.recoveryPhone, recoveryEmail: response.data.hasRecoveryEmail ? '***************' : '' }
              })
              emit('closeContactPillModal')
            })
          }
        }
      } else if (!requiredValidationActive.value) {
        const updateBody = {}
        updateBody.id = store.state.profileData.id
        updateBody[props.propertyToUpdate] = pillModalValue.value
        store.dispatch({
          type: 'updateProfile',
          body: updateBody
        }).then(() => {
          emit('closeContactPillModal')
        })
      }
    }

    /**
     * @description Emit to inform that the mode value is modified.
     */
    function informPillIsTouch () {
      if (emailValidationError.value) {
        emailValidationError.value = false
      }
      emit('pillIsTouch')
    }

    /**
     * @description key press event handler.
     * @param event DOM event
     */
    function validateKeyPress (event) {
      if (mobileNumberInput.value && (event.keyCode < 48 || event.keyCode > 57)) event.preventDefault()
    }

    /**
     * @description key down event handler.
     * @param event DOM event
     */
    function validateKeyDown () {
      if (!props.propertyToUpdate.includes('recoveryEmail') && navigator.userAgent.match(/Android/i)) {
        const inputValue = document.getElementsByClassName('containerContactPillModal__content--input')[0]
        keyDownValue.value = inputValue.value
      }
    }

    /**
     * @description key up event handler.
     * @param event DOM event
     */
    function validateKeyUp () {
      if (!props.propertyToUpdate.includes('recoveryEmail') && navigator.userAgent.match(/Android/i)) {
        const inputValue = document.getElementsByClassName('containerContactPillModal__content--input')[0]
        const lastKeyCode = inputValue.value.charCodeAt(inputValue.value.length - 1)
        if ((lastKeyCode < 48 || lastKeyCode > 57)) {
          setTimeout(() => {
            pillModalValue.value = keyDownValue.value
            inputValue.value = keyDownValue.value
          })
        }
      }
    }

    /**
     * @description Check for pasted value
     * @param event User generated event
     */
    function inputPaste (event) {
      const clipData = event.clipboardData || window.clipboardData
      if (mobileNumberInput.value && !/^[0-9]+$/.test(clipData.getData('Text'))) event.preventDefault()
    }

    /**
     * @description validate the email model with the regular expresion
     */
    function validateEmail () {
      /* eslint-disable */
      return /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(pillModalValue.value)
      /* eslint-enable */
    }

    return {
      store,
      enableEdition,
      modalIcon,
      modalLabel,
      pillModalValue,
      requiredValidationActive,
      clickOutside,
      clickStep1Handler,
      savePillValue,
      showMobileVersion,
      informPillIsTouch,
      mobileNumberInput,
      validateKeyPress,
      inputPaste,
      validateKeyDown,
      validateKeyUp,
      keyDownValue,
      emailValidationError
    }
  }
}
</script>
