<template>
<section class="detailExperienceContainer">
  <section class="main mainExperience">
    <section :class="['main__experience', {'editExperienceMobileContainer customScrollbar': sections.experience.isEditing && store.state.isPhone}]">
      <div :class="['profileHeading', 'mainHeading', {'editExperienceMobileHeading': sections.experience.isEditing && store.state.isPhone}]">
        <span>EXPERIENCE</span>
        <span v-if="sections.experience.isEditing && store.state.isPhone" class="editExperienceMobileIcon grey-icon-close" @click="cancel('experience')"></span>
        <span
          v-if="showEditIcon"
          class="grey-icon-edit editIcon"
          id="editExperienceIcon"
          :class="{'disabledIcon': sections.experience.isEditing}"
          @click="toggleEditionMode('experience', true)"></span>
        <highlightedBox
          class="detailExperienceContainer__mainExperience--walkthrough"
          queryElementToHighLight="#editExperienceIcon"
          v-if="store.getters.isWalkthroughVisible('PPRO_profileEdit')"
          :stepNumber=2 :activeStepNumber="store.state.walkthroughs.PPRO_profileEdit.currentStep"
        />
      </div>
      <section v-if="!sections.experience.isEditing">
        <div class="contentData">
          <p class="main__experience--subTitlesProfile">EXPERIENCE AT GREY</p>
          <span :class="['contentData__profileDescription', {'notSpecified': !profileData.description}]">{{ stripHTML(profileData.description) }}</span>
        </div>
        <div class="contentData">
          <p class="main__experience--subTitlesProfile">RECENT EXPERIENCE</p>
          <span :class="['contentData__profileDescription', {'notSpecified': !profileData.recent_experience}]">{{ stringValue(profileData.recent_experience) }}</span>
        </div>
        <div class="contentData">
          <p class="main__experience--subTitlesProfile">PAST EXPERIENCE</p>
          <span :class="['contentData__profileDescription', {'notSpecified': !profileData.past_experience}]">{{ stringValue(profileData.past_experience) }}</span>
        </div>
        <div class="contentData">
          <p class="main__experience--subTitlesProfile">INDUSTRY EXPERIENCE</p>
          <div v-if="profileData.categories.length > 0" class="contentData__profileChipWrapper">
            <span v-for="(industry, index) in profileData.categories" :key="index" class="contentData__profileChip">{{ industry }}</span>
          </div>
          <p v-else class="contentData__profileDescription notSpecified">{{ emptyField }}</p>
        </div>
        <div class="contentData">
          <p class="main__experience--subTitlesProfile">GREY CLIENTS</p>
          <div v-if="profileData.client.length > 0 && profileData.client[0] !== 'None'" class="contentData__profileChipWrapper">
            <span v-for="(client, index) in profileData.client" :key="index" class="contentData__profileChip">{{ client === 'None' ? emptyField : client }}</span>
          </div>
          <p v-else class="contentData__profileDescription notSpecified">{{ emptyField }}</p>
        </div>
        <div class="contentData">
          <p class="main__experience--subTitlesProfile">OTHER CLIENTS</p>
          <div v-if="profileData.past_clients.length > 0" class="contentData__profileChipWrapper">
            <span v-for="(pastClient, index) in profileData.past_clients" :key="index" class="contentData__profileChip">{{ pastClient }}</span>
          </div>
          <p v-else class="contentData__profileDescription notSpecified">{{ emptyField }}</p>
        </div>
      </section>
      <section v-else :class="['main__experience--editableContent', {'editExperienceMobileEditableContent': store.state.isPhone}]">
        <div class="contentEditableData">
          <p class="main__experience--subTitlesProfile">EXPERIENCE AT GREY *</p>
          <editableInput
            :isAnError="isObjectEmptyValidations(editableInputs.experienceAtGrey)"
            v-model="editableInputs.experienceAtGrey"
            :focus="true"
            @unsavedChanges="activateUnsavedChanges"
            instructions="This field must have at least 50 characters."
          />
          <span class="errorMessage" v-if="isObjectEmptyValidations(editableInputs.experienceAtGrey)">{{ requiredMessage }}</span>
        </div>
        <div class="contentEditableData">
          <p class="main__experience--subTitlesProfile">RECENT EXPERIENCE *</p>
          <editableInput
            :isAnError="isObjectEmptyValidations(editableInputs.recentExperience)"
            v-model="editableInputs.recentExperience"
            :focus="false"
            @unsavedChanges="activateUnsavedChanges"
            instructions="This field must have at least 50 characters."
          />
          <span class="errorMessage" v-if="isObjectEmptyValidations(editableInputs.recentExperience)">{{ requiredMessage }}</span>
        </div>
        <div class="contentEditableData">
          <p class="main__experience--subTitlesProfile">PAST EXPERIENCE (Optional)</p>
          <editableInput v-model="editableInputs.pastExperience" :focus="false" @unsavedChanges="activateUnsavedChanges" />
        </div>
        <div class="contentEditableData">
          <p class="main__experience--subTitlesProfile">INDUSTRY EXPERIENCE *</p>
          <inputTree :tree="store.state.people.profileCategories" v-model="dropDownFields.industryExperience" :class="{error : isArrayEmptyValidations(dropDownFields.industryExperience)}"/>
          <span class="errorMessage" v-if="isArrayEmptyValidations(dropDownFields.industryExperience)">{{ requiredMessage }}</span>
        </div>
        <div class="contentEditableData">
          <p class="main__experience--subTitlesProfile">GREY CLIENTS *</p>
          <inputTree :tree="store.state.people.profileClients" v-model="dropDownFields.greyClients" separator='/' :class="{error : isArrayEmptyValidations(dropDownFields.greyClients)}"/>
          <span class="errorMessage" v-if="isArrayEmptyValidations(dropDownFields.greyClients)">{{ requiredMessage }}</span>
        </div>
        <div class="contentEditableData">
          <p class="main__experience--subTitlesProfile">OTHER CLIENTS (Optional)</p>
          <editablePillsInput v-model="editableInputs.otherClients" @unsavedChanges="activateUnsavedChanges" />
        </div>
        <section class="editButtons">
          <button class="editButtons__cancelButton" @click="cancel('experience')" :disabled="store.state.savingProfile">CANCEL</button>
          <button class="editButtons__submitButton" @click="save('experience')" :disabled="store.state.savingProfile">SAVE</button>
        </section>
      </section>
    </section>
    <section :class="['main__experience', {'editExperienceMobileContainer customScrollbar': sections.skills.isEditing && store.state.isPhone}]">
      <div :class="['profileHeading', {'editExperienceMobileHeading': sections.skills.isEditing && store.state.isPhone}]">
        <span>SKILLS</span>
        <span v-if="sections.skills.isEditing && store.state.isPhone" class="editExperienceMobileIcon grey-icon-close" @click="cancel('skills')"></span>
        <span v-if="showEditIcon" class="grey-icon-edit editIcon" :class="{'disabledIcon': sections.skills.isEditing}" @click="toggleEditionMode('skills', true)"></span>
      </div>
      <section v-if="!sections.skills.isEditing">
        <div class="contentData">
          <p class="main__experience--subTitlesProfile">SKILLS</p>
          <div v-if="profileData.skills.length > 0" class="contentData__profileChipWrapper">
            <span v-for="(skill, index) in profileData.skills" :key="index" class="contentData__profileChip">{{ skill }}</span>
          </div>
          <p v-else class="contentData__profileDescription notSpecified">{{ emptyField }}</p>
        </div>
        <div class="contentData">
          <p class="main__experience--subTitlesProfile">LANGUAGES</p>
          <div v-if="profileData.languages.length > 0" class="contentData__profileChipWrapper">
            <span v-for="(languages, index) in profileData.languages" :key="index" class="contentData__profileChip camelCase">{{ languages }}</span>
          </div>
          <p v-else class="contentData__profileDescription notSpecified">{{ emptyField }}</p>
        </div>
        <div class="contentData">
          <p class="main__experience--subTitlesProfile">DESCRIBE YOUR SUPERPOWER</p>
          <span :class="['contentData__profileDescription', {'notSpecified': !profileData.superpower}]">{{ stringValue(profileData.superpower) }}</span>
        </div>
      </section>
      <section v-else :class="['main__experience--editableContent', {'editExperienceMobileEditableContent': store.state.isPhone}]">
        <div class="contentEditableData">
          <p class="main__experience--subTitlesProfile">SKILLS *</p>
          <dropdownInput
            :isArrowVisible="true"
            @unsavedChanges="activateUnsavedChanges($event)"
            :isAnError="isArrayEmptyValidations(dropDownFields.skills)"
            :options="store.state.people.metadata.skills" v-model="dropDownFields.skills"
            instructions="Select at least 5 skills"
          />
          <span class="errorMessage" v-if="isArrayEmptyValidations(dropDownFields.skills)">{{ requiredMessage }}</span>
        </div>
        <div class="contentEditableData">
          <p class="main__experience--subTitlesProfile">LANGUAGES *</p>
          <dropdownInput
            :isArrowVisible="true"
            @unsavedChanges="activateUnsavedChanges($event)"
            :isAnError="isArrayEmptyValidations(dropDownFields.languages)"
            :options="store.state.people.metadata.languages" v-model="dropDownFields.languages"
          />
          <span class="errorMessage" v-if="isArrayEmptyValidations(dropDownFields.languages)">{{ requiredMessage }}</span>
        </div>
        <div class="contentEditableData">
          <p class="main__experience--subTitlesProfile">DESCRIBE YOUR SUPERPOWER *</p>
          <editableInput
            v-model="editableInputs.superPower"
            :focus="false"
            @unsavedChanges="activateUnsavedChanges"
            :isAnError="isObjectEmptyValidations(editableInputs.superPower)"
            instructions="This field must have at least 50 characters."
          />
          <span class="errorMessage" v-if="isObjectEmptyValidations(editableInputs.superPower)">{{ requiredMessage }}</span>
        </div>
        <section class="editButtons">
          <button class="editButtons__cancelButton" @click="cancel('skills')" :disabled="store.state.savingProfile">CANCEL</button>
          <button class="editButtons__submitButton" @click="save('skills')" :disabled="store.state.savingProfile">SAVE</button>
        </section>
      </section>
    </section>
    <section :class="['main__experience', {'editExperienceMobileContainer customScrollbar': sections.awards.isEditing && store.state.isPhone}]">
      <div :class="['profileHeading', {'editExperienceMobileHeading': sections.awards.isEditing && store.state.isPhone}]">
        <span>AWARDS</span>
        <span v-if="sections.awards.isEditing && store.state.isPhone" class="editExperienceMobileIcon grey-icon-close" @click="cancel('awards')"></span>
        <span v-if="showEditIcon" class="grey-icon-edit editIcon" :class="{'disabledIcon': sections.awards.isEditing}" @click="toggleEditionMode('awards', true)"></span>
      </div>
      <section v-if="!sections.awards.isEditing">
        <div class="contentData">
          <p class="main__experience--subTitlesProfile">AWARDS</p>
          <div v-if="profileData.awards.length > 0" class="contentData__profileChipWrapper">
            <span v-for="(awards, index) in profileData.awards" :key="index" class="contentData__profileChip camelCase">{{ awards }}</span>
          </div>
          <p v-else class="contentData__profileDescription notSpecified">{{ emptyField }}</p>
        </div>
        <div class="contentData">
          <p class="main__experience--subTitlesProfile">OTHER AWARDS</p>
          <span :class="['contentData__profileDescription', {'notSpecified': profileData.other_awards.length === 0}]">{{ arrayValue(profileData.other_awards, true) }}</span>
        </div>
      </section>
      <section v-else :class="['main__experience--editableContent', {'editExperienceMobileEditableContent': store.state.isPhone}]">
        <div class="contentEditableData">
          <p class="main__experience--subTitlesProfile">AWARDS (Optional)</p>
          <dropdownInput :isArrowVisible="true" @unsavedChanges="activateUnsavedChanges($event)" :options="store.state.people.metadata.awards" v-model="dropDownFields.awards" />
        </div>
        <div class="contentEditableData">
          <p class="main__experience--subTitlesProfile">OTHER AWARDS (Optional)</p>
          <editableInput v-model="editableInputs.otherAwards" :focus="false" @unsavedChanges="activateUnsavedChanges" />
        </div>
        <section class="editButtons">
          <button class="editButtons__cancelButton" @click="cancel('awards')" :disabled="store.state.savingProfile">CANCEL</button>
          <button class="editButtons__submitButton" @click="save('awards')" :disabled="store.state.savingProfile">SAVE</button>
        </section>
      </section>
    </section>
    <section :class="['main__experience', {'editExperienceMobileContainer customScrollbar': sections.others.isEditing && store.state.isPhone}]">
      <div :class="['profileHeading', {'editExperienceMobileHeading': sections.others.isEditing && store.state.isPhone}]">
        <span>OTHERS</span>
        <span v-if="sections.others.isEditing && store.state.isPhone" class="editExperienceMobileIcon grey-icon-close" @click="cancel('others')"></span>
        <span v-if="showEditIcon" class="grey-icon-edit editIcon" :class="{'disabledIcon': sections.others.isEditing}" @click="toggleEditionModeOthers"></span>
      </div>
      <section v-if="!sections.others.isEditing">
        <div class="contentData">
          <p class="main__experience--subTitlesProfile">SPECIAL INTERESTS</p>
          <span :class="['contentData__profileDescription', {'notSpecified': profileData.hobbies_interests.length === 0}]">{{ arrayValue(profileData.hobbies_interests, true) }}</span>
        </div>
        <div class="contentData">
          <p class="main__experience--subTitlesProfile">TAGS</p>
          <div v-if="profileData.tags && profileData.tags.length > 0" class="contentData__profileChipWrapper">
            <span v-for="(tag, index) in profileData.tags" :key="index" class="contentData__profileChip camelCase">{{ tag }}</span>
          </div>
          <p v-else class="contentData__profileDescription notSpecified">{{ emptyField }}</p>
        </div>
        <div class="contentData">
          <p class="main__experience--subTitlesProfile">GREY START DATE</p>
          <span class="contentData__profileDescription">{{ profileData.start_date ? profileData.start_date : emptyField }}</span>
        </div>
      </section>
      <section v-else :class="['main__experience--editableContent', {'editExperienceMobileEditableContent': store.state.isPhone}]">
        <div class="contentEditableData">
          <p class="main__experience--subTitlesProfile">SPECIAL INTERESTS (Optional)</p>
          <editableInput v-model="editableInputs.specialInterest" :focus="true" @unsavedChanges="activateUnsavedChanges" />
        </div>
        <div class="contentEditableData">
          <p class="main__experience--subTitlesProfile">TAGS (Optional)</p>
          <dropdownInput
            :isArrowVisible="true"
            @unsavedChanges="activateUnsavedChanges($event)"
            :options="store.state.people.metadata.tags"
            v-model="dropDownFields.tags"
            userValuesAllowed
          />
        </div>
        <div class="contentEditableData">
          <p class="main__experience--subTitlesProfile">GREY START DATE</p>
          <editableInput
            :contentEditable="false"
            :isAnError="isObjectEmptyValidations(editableInputs.greyStartDate)"
            v-model="editableInputs.greyStartDate"
            :focus="false"
            :placeHolder="'Eg. Aug-2014 …'"
            :hasCharCounter="false"
            @unsavedChanges="activateUnsavedChanges"
          />
          <span class="errorMessage" v-if="isObjectEmptyValidations(editableInputs.greyStartDate)">{{ requiredMessage }}</span>
        </div>
        <section class="editButtons">
          <button class="editButtons__cancelButton" @click="cancel('others')" :disabled="store.state.savingProfile">CANCEL</button>
          <button class="editButtons__submitButton" @click="save('others')" :disabled="store.state.savingProfile">SAVE</button>
        </section>
      </section>
    </section>
  </section>
</section>
</template>

<script>
import { useStore } from 'vuex'
import dropdownInput from '@/components/dropdownInput/dropdownInput'
import editableInput from '@/components/editableInput/editableInput'
import editablePillsInput from '@/components/editablePillsInput/editablePillsInput'
import inputTree from '../inputTree/inputTree'
import highlightedBox from '@/components/highlightedBox/highlightedBox'
import { computed, nextTick, reactive, ref, watch } from 'vue'

export default {
  name: 'detailExperience',
  components: {
    dropdownInput,
    editableInput,
    editablePillsInput,
    inputTree,
    highlightedBox
  },
  props: ['profileData', 'emptyField'],
  emits: ['openDiscardChangesModal', 'openApiErrorModal', 'handleUnsavedChangesListener', 'savedChanges'],
  setup (props, { emit }) {
    const store = useStore()
    const unsavedChanges = ref(false)
    const validateFields = ref(false)
    const sectionEdited = computed(() => store.state.sectionEdited)
    const requiredMessage = ref('This field cannot be empty')
    const dropDownFields = reactive({
      industryExperience: [],
      greyClients: [],
      skills: [],
      languages: [],
      awards: [],
      tags: []
    })
    const editableInputs = reactive({
      experienceAtGrey: null,
      recentExperience: null,
      pastExperience: null,
      otherClients: null,
      superPower: null,
      otherAwards: null,
      specialInterest: null,
      greyStartDate: null
    })
    const sections = reactive({
      experience: {
        isEditing: false
      },
      skills: {
        isEditing: false
      },
      awards: {
        isEditing: false
      },
      others: {
        isEditing: false
      }
    })
    const scrollPosition = ref(0)

    /**
      * @description Validate if edit icon should be displayed
      */
    const showEditIcon = computed(() => {
      return store.getters.isUserLogged(props.profileData.id) && store.getters.actionCanBePerformed('edit', 'profileInformation')
    })

    /***
      * @description remove html tags
      * @param string value to be evaluated
      */
    const stripHTML = computed(() => (string) => {
      return string ? string.replace(/<[^>]+>/g, '') : props.emptyField
    })

    /**
      * @description Updates the visiibility of the form based on the user request.
      * @param {sectionName} string with the name of the section to be edited.
      */
    watch(() => store.state.sectionEdited, (sectionName) => {
      if (sectionName) {
        Object.keys(sections).forEach(section => {
          sections[section].isEditing = false
        })
        if (sections[sectionName]) {
          sections[sectionName].isEditing = true
          nextTick(() => {
            if (store.state.isPhone) {
              document.querySelector('.editExperienceMobileContainer').scrollTop = 0
            }
          })
        }
      }
    })

    /**
     * @description returns value if apply if not empty field
     * @param paramValue value to evaluate
     */
    function stringValue (paramValue) {
      /* eslint-disable */
      return paramValue ? paramValue : props.emptyField
      /* eslint-enable */
    }

    /**
      * @description returns value concatenated as a string if apply if not empty field
      * @param paramValue value to evaluate
      * @param empField flag indicating if empty field must be included
      */
    function arrayValue (paramValue, empField) {
      return paramValue.length > 0 || !empField ? paramValue.join(', ') : props.emptyField
    }

    /**
      * @description Manage start editing mode in others section to refresh metadata with tags list updated.
      */
    function toggleEditionModeOthers () {
      store.dispatch({
        type: 'getPeopleMetadata'
      }).then(() => {
        toggleEditionMode('others', true)
      })
    }

    /**
      * @description Launches a state change related to a new edition request of a section has been launched.
      * @param {section} string with the name of section to be edited.
      * @param {isEditin} boolean to know if the section will be edited or not.
      */
    function toggleEditionMode (section, isEditing) {
      store.commit({
        type: 'SET_SECTION_EDITED',
        sectionEdited: isEditing ? section : ''
      })

      if (!isEditing) {
        if (store.state.isPhone) {
          document.querySelector('#app').scrollTop = scrollPosition.value
        }
        if (section) {
          sections[section].isEditing = false
        }
      } else {
        if (store.state.isPhone) {
          scrollPosition.value = document.querySelector('#app').scrollTop
        }
        activateEdition(section)
      }
    }

    /**
      * @description Validate that an object exists and it has a valid value.
      */
    function isObjectEmpty (obj) {
      return Boolean(!obj || !obj.trim() || obj.value === null)
    }

    /**
      * @description Validate that an array exists and it has at least one element.
      */
    function isArrayEmpty (array) {
      return Boolean(!array || array.length === 0)
    }

    /**
      * @description Validate that an object exists and it has a valid value when validations is on.
      */
    function isObjectEmptyValidations (obj) {
      return validateFields.value && isObjectEmpty(obj)
    }

    /**
      * @description Validate that an array exists and it has at least one element when validations is on.
      */
    function isArrayEmptyValidations (array) {
      return validateFields.value && isArrayEmpty(array)
    }

    /**
      * @description Enable edition mode of the given section.
      * @param sectionName name of the section to be edited
      */
    function activateEdition (sectionName) {
      validateFields.value = false
      unsavedChanges.value = false
      switch (sectionName) {
        case 'experience':
          dropDownFields.greyClients = previousSelectedValues(props.profileData.client, store.state.people.metadata.clients)
          editableInputs.experienceAtGrey = props.profileData.description
          editableInputs.recentExperience = props.profileData.recent_experience
          editableInputs.pastExperience = props.profileData.past_experience
          editableInputs.otherClients = props.profileData.past_clients
          break
        case 'skills':
          dropDownFields.skills = previousSelectedValues(props.profileData.skills, store.state.people.metadata.skills)
          dropDownFields.languages = previousSelectedValues(props.profileData.languages, store.state.people.metadata.languages)
          editableInputs.superPower = props.profileData.superpower
          break
        case 'awards':
          dropDownFields.awards = previousSelectedValues(props.profileData.awards, store.state.people.metadata.awards)
          editableInputs.otherAwards = arrayValue(props.profileData.other_awards, false)
          break
        case 'others':
          editableInputs.specialInterest = arrayValue(props.profileData.hobbies_interests, false)
          dropDownFields.tags = previousSelectedValues(props.profileData.tags, store.state.people.metadata.tags)
          editableInputs.greyStartDate = props.profileData.start_date
          break
      }
    }

    /**
      * @description cancel changes made on given section
      * @param sectionName name of the section to cancel edition
      */
    function cancel (sectionName) {
      if (unsavedChanges.value) {
        emit('openDiscardChangesModal')
      } else {
        toggleEditionMode(sectionName, false)
      }
    }

    /**
      * @description Closes the modal and discard unsaved changes.
      */
    function closeForm () {
      toggleEditionMode(store.state.sectionEdited, false)
    }

    /**
      * @description save changes made on the given section
      * @param sectionName name of the section to be saved
      */
    function save (sectionName) {
      validateFields.value = false
      if (fieldValuesEmpty(sectionName)) {
        validateFields.value = true
      } else {
        let body = {}
        switch (sectionName) {
          case 'experience':
            body = {
              id: props.profileData.id,
              description: editableInputs.experienceAtGrey,
              recent_experience: editableInputs.recentExperience,
              past_experience: editableInputs.pastExperience,
              past_clients: editableInputs.otherClients,
              categories: dropDownFields.industryExperience,
              client: dropDownFields.greyClients
            }
            break
          case 'skills':
            body = {
              id: props.profileData.id,
              superpower: editableInputs.superPower,
              skills: newSelectedValues(dropDownFields.skills),
              languages: newSelectedValues(dropDownFields.languages)
            }
            break
          case 'awards':
            body = {
              id: props.profileData.id,
              other_awards: newOptionalValue(editableInputs.otherAwards),
              awards: newSelectedValues(dropDownFields.awards)
            }
            break
          case 'others':
            body = {
              id: props.profileData.id,
              hobbies_interests: newOptionalValue(editableInputs.specialInterest),
              start_date: editableInputs.greyStartDate,
              tags: newSelectedValues(dropDownFields.tags)
            }
            break
        }

        store.dispatch({
          type: 'updateProfile',
          body: body
        }).then(() => {
          toggleEditionMode(sectionName, false)
          emit('savedChanges')
        }).catch(() => {
          emit('openApiErrorModal')
        })
      }
    }

    /**
      * @description validate required fields on given section
      * @param sectionName name of the section to validate
      */
    function fieldValuesEmpty (sectionName) {
      var validation = false
      switch (sectionName) {
        case 'experience':
          validation = isObjectEmpty(editableInputs.experienceAtGrey) || isObjectEmpty(editableInputs.recentExperience) ||
          isArrayEmpty(dropDownFields.industryExperience) || isArrayEmpty(dropDownFields.greyClients.length)
          break
        case 'skills':
          validation = isArrayEmpty(dropDownFields.skills.length) || isArrayEmpty(dropDownFields.languages) || isObjectEmpty(editableInputs.superPower)
          break
        case 'others':
          validation = isObjectEmpty(editableInputs.greyStartDate)
          break
      }
      return validation
    }

    /**
      * @description flag indicating unsaved changes in one of the editable fields.
      * @param {Event} event of input change fired by user.
      */
    function activateUnsavedChanges (event) {
      unsavedChanges.value = true
      emit('handleUnsavedChangesListener')
      if (!store.state.formIsTouched) {
        store.commit({
          type: 'SET_FORM_TOUCHED',
          formIsTouched: true
        })
      }
    }

    /**
      * @description parse selected values from the complete list matching by the description
      * @param  previousSelected current selected values
      * @param allValues complete list values
      */
    function previousSelectedValues (previousSelected, allValues) {
      var selectedValues = []
      previousSelected.forEach(prevSelected => {
        const item = allValues.filter(value => value.value.toLowerCase().trim() === prevSelected.toLowerCase().trim())
        if (item && item.length > 0) {
          selectedValues.push(item[0])
        }
      })
      return selectedValues
    }

    /**
      * @description parse selected values from the complete list matching by the description
      * @param  selectedValues current selected values
      */
    function newSelectedValues (selectedValues) {
      var newSelectedValues = []
      selectedValues.forEach(selectedValue => {
        newSelectedValues.push(selectedValue.value)
      })
      return newSelectedValues
    }

    /**
      * @description parse new string comma separated value into a string array
      * @param  newValue current selected values
      */
    function newOptionalValue (newValue) {
      var newValues = []
      if (newValue) {
        const splitValues = newValue.split(',')
        splitValues.forEach(splitValue => {
          newValues.push(splitValue.trim())
        })
      }
      return newValues
    }
    return {
      store,
      requiredMessage,
      showEditIcon,
      stripHTML,
      stringValue,
      toggleEditionModeOthers,
      isObjectEmptyValidations,
      isArrayEmptyValidations,
      cancel,
      closeForm,
      save,
      activateUnsavedChanges,
      sections,
      arrayValue,
      sectionEdited,
      editableInputs,
      toggleEditionMode,
      dropDownFields
    }
  }
}
</script>
