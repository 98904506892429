<template>
  <genericModal
    title="Oops!"
    closeIcon="grey-icon-close"
    width="400px"
    height="230px"
    class="containerAPIErrorModal"
    ref="modal"
    v-slot="genericModalProps"
  >
    <span class="containerAPIErrorModal__message">Something went wrong. Please try again.</span>
    <button class="containerAPIErrorModal__button" @click="genericModalProps.close" type="button">OK</button>
  </genericModal>
</template>

<script>
import { ref } from 'vue'
import genericModal from '../genericModal/genericModal'

export default {
  name: 'apiErrorModal',
  components: {
    genericModal
  },
  setup (props) {
    const modal = ref(null)

    /**
     * @description Opens the modal to show an error message.
     */
    function open () {
      modal.value.open()
    }

    return {
      modal,
      open
    }
  }
}
</script>
