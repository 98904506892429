<template>
  <section class="containerInputList">
    <input class="containerInputList__input" :class="{'noBottomBorders': showOptions && areThereOptions}" @keydown="keyHandler($event)" ref="input"
    @focus="toogleOptionsVisibility(true)" @blur="blurHandler()" placeholder="Start typing..."/>
    <section class="containerInputList__containerList" ref="containerList" v-if="showOptions && areThereOptions">
      <ul class="containerInputList__containerList--list" ref="list">
        <li v-for="(option, index) in options"
            :key="index"
            @mousedown.stop="selectOptionClick($event, option)"
            class="item"
            :class="{selected: index === selectionIndex}"
        >{{ option.label || option }}</li>
      </ul>
    </section>
  </section>
</template>

<script>
import { computed, nextTick, onMounted, ref } from 'vue'
export default {
  name: 'inputList',
  props: {
    optionList: {
      type: Array
    },
    optionsPromise: {
      type: Function
    },
    modelValue: {}
  },
  emits: ['update:modelValue', 'touched'],
  setup (props, { emit }) {
    const selectionIndex = ref(0)
    const showOptions = ref(false)
    const options = ref([])
    const throttleTimer = ref(null)
    const invalidInput = ref(false)
    const touchedInput = ref(false)
    const input = ref(null)
    const containerList = ref(null)
    const list = ref(null)

    onMounted(() => {
      input.value.value = typeof props.modelValue === 'string' ? props.modelValue : props.modelValue.value
      filterOptions(props.modelValue)
    })

    /**
     * @description Indicates if there are any option to show.
     */
    const areThereOptions = computed(() => {
      return options.value && options.value.length > 0
    })

    /**
     * @description Selects an option when user clicks on it.
     * @param {event} event fired by the user.
     * @param {option} object selected by the user.
     */
    function selectOptionClick (event, option) {
      selectOption(option)
      event.preventDefault()
      input.value.blur()
    }

    /**
     * @description Mark input as invalid and sets an empty value on the input.
     */
    function blurHandler () {
      setTimeout(() => {
        toogleOptionsVisibility(false)
        if (invalidInput.value) {
          input.value.value = ''
          filterOptions('')
          emit('update:modelValue', null)
        }
      })
    }

    /**
     * @description Sets options as visible or hidden.
     */
    function toogleOptionsVisibility (isVisible) {
      showOptions.value = isVisible
    }

    /**
     * @description Aplies a filter to option list using a given term.
     * @param {term} string to use as filter on the list.
     */
    function filterOptions (term) {
      selectionIndex.value = 0
      if (props.optionList) {
        options.value = filterSyncOptions(term)
      } else {
        if (term) {
          filterWithPromise(term).then(
            (response) => {
              options.value = response
            }
          )
        } else {
          options.value = []
        }
      }
    }

    /**
     * @description Calls a given function to make a search using a given term as filter.
     * @param {term} string to use as filter.
     */
    function filterWithPromise (term) {
      return props.optionsPromise(term).then(options => {
        return options
      })
    }

    /**
     * @description Filters the list of options using a given term.
     * @param {term} string to use as filter.
     */
    function filterSyncOptions (term) {
      return props.optionList.filter(option => option.toLowerCase().indexOf(term.toLowerCase().trim()) >= 0)
    }

    /**
     * @description Selects an option using the keyboard.
     */
    function selectOptionFromList () {
      const option = options.value[selectionIndex.value]

      if (option) {
        selectOption(option)
      }
      toogleOptionsVisibility(false)
    }

    /**
     * @description Marks an option as selected.
     * @param {option} object selected by the user.
     */
    function selectOption (option) {
      invalidInput.value = false
      emit('touched')
      emit('update:modelValue', option)
      input.value.value = option.label || option
      filterOptions(option.label || option)
    }

    /**
     * @description Manages logic of key pressed and based on user key pressed.
     * @param event event send by user at moment of press a key.
     */
    function keyHandler (event) {
      event.stopPropagation()
      event.stopImmediatePropagation()
      switch (event.key) {
        case 'ArrowUp':
          moveSelection(-1)
          break
        case 'ArrowDown':
          moveSelection(1)
          break
        case 'Enter':
          selectOptionFromList()
          break
        case 'Tab':
          break
        default:
          invalidInput.value = true
          callThrotle()
          break
      }
    }

    /**
     * @description Makes a search using a throtle to avoid overload search API.
     */
    function callThrotle () {
      emit('touched')
      clearTimeout(throttleTimer.value)
      throttleTimer.value = setTimeout(() => {
        toogleOptionsVisibility(true)
        filterOptions(input.value.value)
      }, 300)
    }

    /**
   * @description Navigates throught the list of items using arrows of  the keyboard.
   * @param increment increment given by the user.
   */
    function moveSelection (increment) {
      if (!areThereOptions.value) {
        return
      }

      toogleOptionsVisibility(true)
      selectionIndex.value += increment

      if (selectionIndex.value >= options.value.length) {
        selectionIndex.value = 0
      }

      if (selectionIndex.value < 0) {
        selectionIndex.value = options.value.length - 1
      }

      nextTick(() => {
        scrollToOption()
      })
    }

    /**
     * @description Moves scroll of the list to show the current item selected.
     */
    function scrollToOption () {
      const topOfContainerList = containerList.value.getBoundingClientRect().top
      const topOfSelectedOption = containerList.value.getElementsByClassName('selected')[0].getBoundingClientRect().top
      const topOfList = list.value.getBoundingClientRect().top

      if (topOfSelectedOption - topOfContainerList > 214) {
        containerList.value.scrollTo(0, topOfSelectedOption - topOfList)
      }

      if (topOfContainerList - topOfSelectedOption > 0) {
        containerList.value.scrollTo(0, containerList.value.scrollTop - (topOfContainerList - topOfSelectedOption))
      }
    }

    return {
      input,
      areThereOptions,
      containerList,
      list,
      touchedInput,
      selectOptionClick,
      blurHandler,
      keyHandler,
      showOptions,
      options,
      selectionIndex,
      toogleOptionsVisibility
    }
  }
}
</script>
