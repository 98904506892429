<template>
  <article class="containerProfileCard">
    <div class="containerProfileCard__userInfo">
      <section class="containerProfileCard__containerProfilePicture">
        <img class="containerProfileCard__containerProfilePicture--profilePicture"
          :src="setProfilePicture"
          :alt="store.state.profileData.title"
          @click="openOverlay"
        >
        <highlightedBox
          class="containerProfileCard__containerProfilePicture--walkthrough"
          queryElementToHighLight=".containerProfileCard__containerProfilePicture--icon"
          @renderFinish="renderFinish('PPRO_profileEdit')"
          v-if="store.getters.isWalkthroughVisible('PPRO_profileEdit')"
          :stepNumber=0 :activeStepNumber="store.state.walkthroughs.PPRO_profileEdit.currentStep"
        />
        <span v-if="showEditProfilePicture"
          class="grey-icon-camera containerProfileCard__containerProfilePicture--icon"
          @click="openGenericModal"
        ></span>
      </section>
      <section class="containerProfileCard__info">
        <div class="containerProfileCard__info--header">
          <div class="profileTitle box-vertical">
            {{ store.state.profileData.title ? store.state.profileData.title : 'No name'}}
          </div>
          <optionsCard :id="store.state.profileData.id" @openCollectionsModal="openCollectionsModal" />
        </div>
        <div class="containerProfileCard__info--content">
          <profileLabels/>
          <profileContactPills></profileContactPills>
          <section v-if="store.getters.isUserLogged(store.state.profileData.id)" class="profilePercent">
            <div class="profilePercent__percentageLabel">
              <span>Your profile is <span :class="labelClass">{{ store.state.profileData.profile_percentage }}%</span> Complete</span>
            </div>
            <div class="profilePercent__containerPercentage">
              <div v-if="store.getters.isUserLogged(store.state.profileData.id)" class="percentageBar">
                <div :class="labelClass" :style="barStyles"></div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </div>
    <section class="containerProfileCard__navigationsButtons">
      <highlightedBox
        class="containerProfileCard__navigationsButtons--walkthrough"
        queryElementToHighLight=".containerProfileCard__navigationsButtons--label"
        v-if="store.getters.isWalkthroughVisible('PPRO_profileEdit')"
        :stepNumber=1 :activeStepNumber="store.state.walkthroughs.PPRO_profileEdit.currentStep"
      />
      <div class="containerProfileCard__navigationsButtons--tabs">
        <span class="profileTab noMargin" :class="{'tabActive': profileTabActive === 'about'}" @click="setActiveTab('about')">About Me</span>
        <span class="profileTab" v-if="showTab('myPortfolio')" :class="{'tabActive': profileTabActive === 'myPortfolio'}" @click="setActiveTab('myPortfolio')">My Portfolio</span>
      </div>
      <div class="containerProfileCard__navigationsButtons--reorder"
        v-if="store.state.dragAndDropVisible && profileTabActive === 'myPortfolio' && store.getters.isUserLogged(store.state.profileData.id)"
        :class="{'dragAndDropEnabled': store.state.dragAndDropVisible && dragAndDropEnabled, 'reorderDisabled': reorderDisabled}">
        <span class="label" :class="{'active': dragAndDropEnabled}">Reorder</span>
        <label class="switch">
          <input type="checkbox" v-model="dragAndDropEnabled" @change="updateDragAndDropEnabled">
          <span class="switch__slider round"></span>
        </label>
      </div>
    </section>
  </article>
</template>

<script>
import { useStore } from 'vuex'
import optionsCard from '@/components/optionsCard/optionsCard'
import profileLabels from '@/components/profileLabels/profileLabels'
import profileContactPills from '@/components/profileContactPills/profileContactPills'
import highlightedBox from '@/components/highlightedBox/highlightedBox'
import { computed, ref } from 'vue'

export default {
  name: 'profileCard',
  components: {
    /* eslint-disable */
    optionsCard,
    /* eslint-enable */
    highlightedBox,
    profileLabels,
    profileContactPills
  },
  props: ['profileTabActive', 'value', 'reorderDisabled'],
  emits: ['openOverlay', 'openUploadModal', 'openCollectionsModal', 'tabSelection'],
  setup (props, { emit }) {
    const store = useStore()
    const dragAndDropEnabled = ref(false)

    /**
      * @description Set profile picture url or set static image
      */
    const setProfilePicture = computed(() => {
      if (!store.state.profileData.thumbnail_url) {
        return '/static/icons/persona-profile.svg'
      } else {
        return store.state.profileData.midsize_picture
      }
    })

    /**
      * @description Return class based on profile percentage
      */
    const labelClass = computed(() => {
      return Number(store.state.profileData.profile_percentage) === 100 ? 'complete' : 'incomplete'
    })

    /**
      * @description Return width style based on profile percentage
      */
    const barStyles = computed(() => {
      return {
        width: `${store.state.profileData.profile_percentage}%`
      }
    })

    /**
     * @description Validate if tab should be displayed
     * @param tabName Name of the tab
     */
    const showTab = computed(() => {
      return tabName => store.getters.isUserLogged(store.state.profileData.id) ? store.getters.actionCanBePerformed('read', tabName) : true
    })

    /**
     * @description Validate if edit profile picture icon should be displayed
     */
    const showEditProfilePicture = computed(() => {
      return store.getters.isUserLogged(store.state.profileData.id) && store.getters.actionCanBePerformed('edit', 'profilePicture')
    })

    /**
      * @description Dispatch emit to open overlay
      */
    function openOverlay () {
      emit('openOverlay', true, setProfilePicture.value)
    }

    /**
      * @description Dispatch emit to open upload modal
      */
    function openGenericModal () {
      emit('openUploadModal')
    }

    /**
      * @description Dispatch emit to open add to collections modal
      * @param itemsId From card
      * @param flag true or false to know if modal is open or close
      */
    function openCollectionsModal (itemsId, flag) {
      emit('openCollectionsModal', itemsId, flag)
    }

    /**
      * @description Emits an event to notify user has selected a diferent tab.
      * @param tabSelected Name of the tab selected.
      */
    function setActiveTab (tabSelected) {
      emit('tabSelection', tabSelected)
    }

    /**
      * @description Notifies a component needed for a walkthorugh has been rendered.
      * @param {walkthroughName} name of the walkthorugh where the element belongs.
      */
    function renderFinish (walkthroughName) {
      store.commit({
        type: 'TOGGLE_WALKTHROUGH_VISIBILITY',
        walkthroughName: walkthroughName,
        property: 'isReadyToRender',
        value: true
      })
    }

    /**
      * @description Emit update on the model variable
      */
    function updateDragAndDropEnabled () {
      store.commit({
        type: 'SET_DRAGDROP_ENABLED',
        dragAndDropEnabled: dragAndDropEnabled.value
      })
    }

    return {
      store,
      dragAndDropEnabled,
      labelClass,
      barStyles,
      showTab,
      showEditProfilePicture,
      openOverlay,
      openGenericModal,
      openCollectionsModal,
      setActiveTab,
      renderFinish,
      updateDragAndDropEnabled,
      setProfilePicture
    }
  }
}
</script>
