<template>
  <section v-if="!editEnabled" class="contactPillModalMultiple" v-click-away.self="clickOutside">
    <section class="contactPillModalMultiple__content" v-if="!editEnabled">
      <div class="contactPillModalMultiple__content--row" v-for="(pill,index) in pillsLst" :key="index" @click.stop="enableEdit(pill)">
        <span :class="pill.icon" @click.stop="enableEdit()"></span>
        <span class="label">{{ contactPillLabel(pill) }}</span>
        <span class="grey-icon-recovery-edit edit-icon" ></span>
      </div>
      <div v-if="showDisclaimer" class="contactPillModalMultiple__content--disclaimer" title="">
        More information: <a class="disclaimer--link" :href="disclaimerUrl('notice')" target="_blank">WPP Global Fair Processing Notice </a> | <a class="disclaimer--link" :href="disclaimerUrl('privacy_policy')" target="_blank">Grey Group Supplier Privacy Policy</a>
      </div>
    </section>
    <span class="containerContactPillModalMultiple__arrowDown"></span>
  </section>
  <template v-if="editEnabled && pillToEdit">
    <contactPillModal
      :modalTitle="pillToEdit.title"
      :propertyToUpdate="pillToEdit.propertyToUpdate"
      :modelValue="contactPillModelValue"
      @pillIsTouch="informPillIsTouch"
      @closeContactPillModal="closeContactPillModalMultiple"
    />
  </template>
</template>

<script>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import contactPillModal from '@/components/contactPillModal/contactPillModal'
import disclaimerUrls from '../../json/disclaimerUrl.json'

export default {
  name: 'contactPillModalMultiple',
  components: { contactPillModal },
  props: {
    pillsLst: {
      type: Array,
      required: true
    },
    showDisclaimer: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['closeContactPillModalMultiple', 'notLoggedUserAction', 'pillIsTouch'],
  setup (props, { emit }) {
    const store = useStore()
    const editEnabled = ref(false)
    const pillToEdit = ref(null)

    /**
     * @description Set contact pill modal model value
     */
    const contactPillModelValue = computed(() => {
      return pillToEdit.value.propertyToUpdate.includes('recoveryEmail') ? '' : pillToEdit.value.modelValue
    })

    /**
     * @description Get URL for disclaimer links
     */
    const disclaimerUrl = computed(() => {
      return type => type === 'privacy_policy' ? disclaimerUrls.urls[0].privacy_policy : disclaimerUrls.urls[0].notice
    })

    /**
     * @description Hide modal whenever user click outside of component
     */
    function clickOutside () {
      if (!store.state.savingProfile) {
        emit('closeContactPillModalMultiple')
      }
    }

    /**
     * @description Hide rows and show the pill modal of the selected label to edit
     * @param pill information to show on the contactPillModal when editing
     */
    function enableEdit (pill) {
      pillToEdit.value = pill
      editEnabled.value = true
    }

    /**
     * @description Emit to inform that the model value is modified.
     */
    function informPillIsTouch () {
      emit('pillIsTouch')
    }

    /**
     * @description Emit to hide the modal
     */
    function closeContactPillModalMultiple () {
      emit('closeContactPillModalMultiple')
    }

    /**
     * @description Set contact pill label
     * @param pill current pill
     */
    function contactPillLabel (pill) {
      return pill.modelValue || 'Not specified'
    }

    return {
      store,
      clickOutside,
      enableEdit,
      editEnabled,
      informPillIsTouch,
      pillToEdit,
      closeContactPillModalMultiple,
      contactPillLabel,
      contactPillModelValue,
      disclaimerUrl
    }
  }
}
</script>
