<template>
  <section class="containerPreviewImage">
    <section class="containerPreviewImage__imageContent">
      <img :src="store.state.base64SrcImage" class="containerPreviewImage__imageContent--image" />
      <span class="containerPreviewImage__imageContent--label">PREVIEW</span>
    </section>
    <section class="containerPreviewImage__buttons">
      <button class="containerPreviewImage__buttons--back"
      :class="{'clickedBackButton': savingImg}"
      @click="back">BACK</button>
      <button v-if="savingImg" class="containerPreviewImage__buttons--saveClicked"
      @click="save">
      <spinner class="spinnerButton" />SAVE</button>
      <button v-else class="containerPreviewImage__buttons--save"
      @click="save">SAVE</button>
    </section>
  </section>
</template>

<script>
import { useStore } from 'vuex'
import { ref } from 'vue'
import spinner from '@/components/spinner/spinner'

export default {
  name: 'previewImage',
  components: {
    spinner
  },
  emits: ['back', 'uploadComplete'],
  setup (props, { emit }) {
    const store = useStore()
    const savingImg = ref(false)

    /**
     * @description Back previuos step (User get to upload image again)
     */
    function back () {
      if (!savingImg.value) {
        store.commit({
          type: 'SET_BASE64_SRC_IMAGE',
          base64SrcImage: null
        })
        emit('back')
      }
    }

    /**
     * @description Convert srcImage to file
     * @param url src of the image
     * @param fileName name of the file image
     */
    function urltoFile (url, fileName) {
      const mimeType = (url.match(/^data:([^;]+);/) || '')[1]
      return (fetch(url)
        .then(function (res) { return res.arrayBuffer() })
        .then(function (buf) { return new File([buf], fileName, { type: mimeType }) })
      )
    }

    /**
     * @description Send image to the BE
     */
    function save () {
      savingImg.value = true
      urltoFile(store.state.base64SrcImage, 'croppedImage.png')
        .then(function (file) {
          store.commit({
            type: 'SAVE_UPLOAD_FILE',
            file: file
          })
          store.dispatch({
            type: 'uploadImage'
          }).then(
            (response) => {
              if (response) {
                emit('uploadComplete')
              }
              savingImg.value = false
            }
          )
        })
    }

    return {
      store,
      back,
      save,
      savingImg
    }
  }
}
</script>
