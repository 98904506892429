<template>
  <section class="containerEditablePills" ref="editablePills">
    <section class="containerEditablePills__containerInput" :class="{'focusEnabled': focusEnabled}">
      <section class="containerEditablePills__containerInput--pills">
        <span v-for="(item, index) in inputOptions" :key="index" class="pill">
          <span class="grey-icon-close pill__closeButton" @click="removeOption(index)"></span>
          <span class="pill__label">{{item}}</span>
        </span>
        <input
          class="multipleInput"
          :placeholder="placeholder"
          :value="currentInput"
          @input="updateInput($event)"
          @blur="setValueEnterByUser(true)"
          @focus="focusEnabled = true"
          @keydown ="keyUpHandler($event)"
          ref="multipleInput"
        />
      </section>
    </section>
  </section>
</template>

<script>
import { nextTick, onMounted, ref } from 'vue'

export default {
  name: 'editablePillsInput',
  props: {
    modelValue: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  emits: ['unsavedChanges', 'update:modelValue'],
  setup (props, { emit }) {
    const currentInput = ref(null)
    const inputOptions = ref([])
    const placeholder = ref('Start typing...')
    const multipleInput = ref()
    const focusEnabled = ref(false)

    onMounted(() => {
      inputOptions.value = props.modelValue.slice()
    })

    /**
     * @description Adds a new option into options array.
     * @param option option selected by the user.
     */
    function addNewOption (option) {
      inputOptions.value.push(option)
      currentInput.value = null
      emitChange()
    }

    /**
     * @description Emit a confirmation that a change has been made.
     */
    function emitChange () {
      emit('unsavedChanges')
      emit('update:modelValue', inputOptions.value)
    }

    /**
     * @description Manages logic of key pressed and based on user key pressed selects function
     * to execute.
     * @param event event send by user at moment of press a key.
     */
    function keyUpHandler (event) {
      event.stopPropagation()
      event.stopImmediatePropagation()
      const notEmptyValue = currentInput.value && currentInput.value.trim() !== ''
      switch (event.key) {
        case 'Enter':
        case 'Tab':
          if (notEmptyValue) {
            event.preventDefault()
            setValueEnterByUser()
            nextTick(() => {
              multipleInput.value.focus()
            })
          }
          break
        case ',':
        case ';':
          if (notEmptyValue) {
            event.preventDefault()
            setValueEnterByUser()
          }
          break
        case 'Backspace':
          if (!currentInput.value) {
            removeOption(inputOptions.value.length - 1)
          }
          break
      }
    }

    /**
     * @description Removes an option from the options list.
     * @param index index of option to be removed.
     */
    function removeOption (index) {
      inputOptions.value.splice(index, 1)
      emitChange()
    }

    /**
     * @description Adds the value enter by user to the options list avoiding duplicated values
     * @param fromView flag indicating if method was called from view
     */
    function setValueEnterByUser (fromView = false) {
      if (fromView) focusEnabled.value = false
      if (!inputOptions.value.includes(currentInput.value.trim())) {
        addNewOption(currentInput.value.trim())
      } else {
        currentInput.value = null
      }
    }

    /**
     * @description Updates value of input model.
     * @param {Event} event of input change fired by user.
     */
    function updateInput (event) {
      const regexToSplitInput = /[,;]+/
      if (event.target.value.search(regexToSplitInput) >= 0) {
        const words = event.target.value.split(regexToSplitInput).filter(word => word && word.trim() !== '')
        words.forEach(word => {
          if (!inputOptions.value.includes(word.trim())) {
            inputOptions.value.push(word.trim())
          }
        })
        emitChange()
      } else {
        currentInput.value = event.target.value
      }
    }

    return {
      multipleInput,
      placeholder,
      addNewOption,
      keyUpHandler,
      updateInput,
      inputOptions,
      currentInput,
      setValueEnterByUser,
      removeOption,
      focusEnabled
    }
  }
}
</script>
