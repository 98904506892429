<template>
  <section v-if="store.state.renderView">
    <backToTopButton @scroll-to-top="scrollToTop" :scrollY="scrollY" />
    <section :class="['containerProfilePage', store.state.isToolbarVertical ? 'resultPageToolbarVertical' : 'resultPageToolbarHorizontal']">
      <section class="containerProfilePage__title">
        <div class="containerProfilePage__title--back" @click="goBack">
          <span v-if="previousState" class="grey-icon-arrow-back icon"></span>
          <span class="label" :class="{'goHome': !previousState}">{{backLabel}}</span>
        </div>
        <button v-if="showSettingsIcon" class="containerProfilePage__title--settings" @click="toggleSettingsVisibility(true)">
          <span class="icon-grey-settings icon" />
          <span class="text">SETTINGS</span>
        </button>
      </section>
      <template v-if="gotProfileData">
        <transition name="slideInFromBottom">
          <fixHeader
            @openCollectionsModal="openCollectionsModal"
            :stickyStart="containerInfoScrollTop"
          ></fixHeader>
        </transition>

        <section class="containerProfilePage__infoUser">
          <profile-card
            :reorderDisabled="reorderDisabled"
            :profileTabActive="store.state.profileTabActive"
            @openOverlay="showBiggerPicture"
            @openUploadModal="openUploadModal"
            @openCollectionsModal="openCollectionsModal"
            @tabSelection="setActiveTab($event)"
          >
          </profile-card>

          <div v-if="route.params.openProfile" class="containerProfilePage__prevNextContainer">
            <a href="#" @click.prevent="goPrevious()" class="containerProfilePage__prevNextContainer--btn" :class="{ 'is-disabled' : isFirstProfile }">
              <span class="grey-icon-arrow-left containerProfilePage__prevNextContainer--btn--icon"></span>
            </a>
            <a href="#" @click.prevent="goNext()" class="containerProfilePage__prevNextContainer--btn" :class="{'is-disabled': isLastProfile }">
              <span class="grey-icon-arrow-right containerProfilePage__prevNextContainer--btn--icon"></span>
            </a>
          </div>
        </section>

        <section v-if="store.state.profileTabActive === 'about'" class="containerProfilePage__contentContainer" ref="profileContentRef">
          <contactInformation
            ref="contactInformationRef"
            :readyProfile="readyProfile"
            :profileData="store.state.profileData"
            @openDiscardChangesModal="openDiscardChangesModal"
            @openApiErrorModal="openApiErrorModal"
            @handleUnsavedChangesListener="manageBeforeUnloadListener()"
            @savedChanges="savedChanges()" />
          <detail-experience
            ref="detailExperienceRef"
            v-if="readyProfile"
            :emptyField="emptyField"
            :profileData="store.state.profileData"
            @openDiscardChangesModal="openDiscardChangesModal"
            @openApiErrorModal="openApiErrorModal"
            @handleUnsavedChangesListener="manageBeforeUnloadListener()"
            @savedChanges="savedChanges()" />
        </section>
        <my-portfolio
          ref="myPortfolioRef"
          v-if="store.state.profileTabActive === 'myPortfolio'"
          :isMyProfile="store.getters.isUserLogged(store.state.profileData.id)"
          @openDeleteConfirmationModal="openDeleteConfirmationModal"
          @setReorderDisabled="setReorderDisabled">
        </my-portfolio>
      </template>
      <skeletonProfile v-else></skeletonProfile>
    </section>
    <overlay v-if="overlayProps.showOverlay"
        :config="overlayProps"
        @closeOverlay="showBiggerPicture"
      >
      </overlay>
    <genericModal
      ref="editPhotoModal"
      title="Upload profile photo"
      closeIcon="grey-icon-close"
      width='608px'
      height='437px'
      theme= "containerGenericModal--profileImageModal">
      <uploadImage @upload="uploadedImage($event)"></uploadImage>
    </genericModal>
    <genericModal
        ref="saveImageModal"
        title="Edit Photo"
        closeIcon="grey-icon-close"
        width='608px'
        height='520px'
        theme= "containerGenericModal--profileImageModal">
      <saveImage :image="imageUploaded" @back="openUploadModal" @showPreviewImage="showPreviewImage"></saveImage>
    </genericModal>
    <genericModal
        ref="previewImageModal"
        title="Edit Photo"
        closeIcon="grey-icon-close"
        width='608px'
        height='520px'
        theme= "containerGenericModal--profileImageModal">
      <previewImage @back="closePreviewModal" @uploadComplete="showSuccessUploadMessage"></previewImage>
    </genericModal>
    <genericModal
      id="imageSavedModal"
      ref="imageSavedModal"
      title="Picture Updated"
      closeIcon="grey-icon-close"
      width='400px'
      height='230px'
      theme="containerGenericModal--imageSavedModal"
      v-slot="genericModalProps">
      <successProcess
        label1="This may take a while."
        @uploadProcessFinish=genericModalProps.close>
      </successProcess>
    </genericModal>
    <addRemoveItemsToCollectionGeneric
      v-if="store.state.collectionsInfo.isCollectionModalOpened"
      @goToNewCollection="forwardToCreateCollectionModal"
      @updateCollectionContainId="updateCollectionContainId($event)"
      :collectionsList="store.state.collectionsMine"
      @close="openCollectionsModal(null, false)"
      :cardsId="store.state.collectionsCardsId">
    </addRemoveItemsToCollectionGeneric>
    <createCollectionModalGeneric
      v-if="createCollectionModalVisible"
      :creatingWithItems="true"
      :cardsId="addCollectionModalID"
      :userLogged="store.state.dataUserLogin.email"
      @cancel-action="backToCollectionModal"
      @close="toggleCreateCollectionsModal(false)">
    </createCollectionModalGeneric>
    <transition enter-active-class="peopleModalAnimationIn" leave-active-class="peopleModalAnimationOut">
      <settings
        v-if="showSettings"
        :mobile="store.state.isPhone"
        @close-settings-menu="toggleSettingsVisibility(false)"
        :ecCode="store.state.profileData.id">
      </settings>
    </transition>
    <genericModal
      ref="deleteConfirmation"
      title="Remove From Portfolio"
      closeIcon="grey-icon-close"
      class="containerMyPortfolio__confirmationDeleteModal"
      width='400px'
      height='230px'
    >
      <p class="containerMyPortfolio__confirmationDeleteModal--message">
        The item will be removed.
      </p>
      <p class="containerMyPortfolio__confirmationDeleteModal--buttons">
        <button class="cancelButton" @click="closeDeleteConfirmationModal">CANCEL</button>
        <button class="removeButton" @click="removeItem">REMOVE</button>
      </p>
    </genericModal>
    <genericModal
      ref="discardBeforeLeaveModalRef"
      title="Exit Profile Edition"
      closeIcon="grey-icon-close"
      class="containerUnsavedChanges__confirmationDeleteModal"
      width='400px'
      height='230px'
    >
      <p class="containerUnsavedChanges__confirmationDeleteModal--message">
        If you exit now you will lose the changes.
      </p>
      <p class="containerUnsavedChanges__confirmationDeleteModal--message">
        Do you want to exit?
      </p>
      <p class="containerUnsavedChanges__confirmationDeleteModal--buttons">
        <button class="cancelButton" @click="cancelDiscardBeforeLeave(true)">CANCEL</button>
        <button class="removeButton" @click="discardBeforeLeave()">YES</button>
      </p>
    </genericModal>
    <discardChangesModal ref="discardChangesModalRef" @discard="closeForm"/>
    <apiErrorModal ref="apiErrorModalRef"/>
    <genericOverlay v-if="showSettings && !store.state.isPhone" @click="toggleSettingsVisibility(false)"/>
    <div class="ddOverlay" v-if="store.state.dragAndDropVisible && store.state.dragAndDropEnabled" />
  </section>
</template>

<script>
import { useStore } from 'vuex'
import ProfileCard from '@/components/cardDetails/profileCard/ProfileCard'
import overlay from '@/components/overlay/overlay'
import contactInformation from '@/components/contactInformation/contactInformation'
import detailExperience from '@/components/detailExperience/detailExperience'
import genericModal from '@/components/genericModal/genericModal'
import uploadImage from '@/components/uploadImage/uploadImage'
import saveImage from '@/components/saveImage/saveImage'
import previewImage from '@/components/previewImage/previewImage'
import successProcess from '@/components/successProcess/successProcess'
import myPortfolio from '@/components/myPortfolio/myPortfolio'
import settings from '@/components/settings/settings'
import genericOverlay from '@/components/genericOverlay/genericOverlay'
import discardChangesModal from '@/components/discardChangesModal/discardChangesModal'
import apiErrorModal from '@/components/apiErrorModal/apiErrorModal'
import fixHeader from '@/components/fixHeader/fixHeader'
import { reactive, ref, computed, onMounted, onBeforeUnmount } from 'vue'
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router'
import skeletonProfile from '@/components/skeletons/skeletonProfile/skeletonProfile'
import $ from 'jquery'

export default {
  name: 'profile',
  components: {
    ProfileCard,
    overlay,
    contactInformation,
    detailExperience,
    genericModal,
    uploadImage,
    saveImage,
    previewImage,
    successProcess,
    myPortfolio,
    settings,
    genericOverlay,
    discardChangesModal,
    apiErrorModal,
    fixHeader,
    skeletonProfile
  },
  emits: ['update-image'],
  setup (props, { emit }) {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const profileIndex = ref(0)
    const gotProfileData = ref(false)
    const readyProfile = ref(false)
    const readyCompletePage = ref(false)
    const tooltipActive = ref(null)
    const tooltipTimeout = ref(null)
    const resultsOffset = ref(0)
    const emptyField = ref('Not specified')
    const overlayProps = reactive({
      showOverlay: false,
      path: ''
    })
    const imageUploaded = ref(null)
    const showSettings = ref(false)
    const itemToDelete = ref(null)
    const reorderDisabled = ref(false)
    const saveImageModal = ref(null)
    const editPhotoModal = ref(null)
    const deleteConfirmation = ref(null)
    const myPortfolioRef = ref(null)
    const discardChangesModalRef = ref(null)
    const contactInformationRef = ref(null)
    const detailExperienceRef = ref(null)
    const apiErrorModalRef = ref(null)
    const imageSavedModal = ref(null)
    const previewImageModal = ref(null)
    const profileContentRef = ref(null)
    const addCollectionModalID = ref(null)
    const discardBeforeLeaveModalRef = ref(false)
    const createCollectionModalVisible = ref(false)
    const scrollY = ref(0)

    document.querySelector('#app').scrollTop = 0

    onBeforeRouteLeave((to, from, next) => {
      if (store.state.formIsTouched) {
        manageBeforeUnloadListener(false)
        toggleDiscardBeforeLeaveModal()
        next(false)
        return ''
      } else {
        manageBeforeUnloadListener(false)
        next()
      }
    })

    onMounted(() => {
      gotProfileData.value = false
      store.commit({
        type: 'SET_THREE_DOTS_MENU_STATE_BY_ITEM',
        item: ''
      })
      if (route.params.id) {
        getProfileData(route.params.id)
      } else {
        console.log('[WARN] No route params!')
      }
      // Animate Profile
      setTimeout(function () {
        readyProfile.value = true
      }, route.params.openProfile ? 100 : 700)

      setTimeout(function () {
        readyCompletePage.value = true
      }, 1000)
      setBackToTopButton()
    })

    onBeforeUnmount(() => {
      gotProfileData.value = false
    })

    const checkMobileState = computed(() => {
      return store.getters.mobileState
    })

    /**
     * @description Validate if the current profile is the last one
     */
    const isLastProfile = computed(() => {
      if (store.state.firstTime) {
        const totalPages = Math.ceil((store.state.totalResultsCount - store.state.initialOffset) / store.state.pagination.size)
        return store.state.pagination.page === totalPages && profileIndex.value === store.state.results.length - 1
      } else {
        return ((profileIndex.value + 1) + (store.state.pagination.size * (store.state.pagination.page - 1))) === store.state.totalResultsCount
      }
    })

    /**
     * @description Validate if the current profile is the first one
     */
    const isFirstProfile = computed(() => {
      return profileIndex.value === 0 && store.state.pagination.page === 1
    })

    /**
     * @description Validate if settings icon should be displayed
     */
    const showSettingsIcon = computed(() => {
      return gotProfileData.value && store.state.profileData && store.getters.isUserLogged(store.state.profileData.id) && store.getters.actionCanBePerformed('edit', 'profileSettings')
    })

    /**
     * @description Return previous state if applies
     */
    const previousState = computed(() => {
      return (document.referrer && !document.referrer.includes('?token=') ? window.history.length > 1 : false) || route.params.openProfile
    })

    /**
     * @description Return offsetTop from profileContentRef
     */
    const containerInfoScrollTop = computed(() => {
      return store.state.isPhone ? 500 : 350
    })

    /**
     * @description Set detail page back label depending on previous state
     */
    const backLabel = computed(() => {
      return previousState.value ? 'Back' : 'PEOPLE OF GREY'
    })

    /**
     * @description gets main container to be able to listen to its scroll
     */
    function setBackToTopButton () {
      const mainSpace = document.querySelector('#containerApp')
      mainSpace.addEventListener('scroll', () => {
        scrollY.value = mainSpace.scrollTop
      })
    }

    /**
     * @description scrolls the page to the top
     */
    function scrollToTop () {
      $('html, #containerApp').animate({ scrollTop: 0 }, 500)
    }

    /**
     * @description get metadata from people service endpoint response
     * @param metadata object from endpoint response that contains the profile metadata
     */
    function setPeopleMetadata (metadata) {
      store.commit({
        type: 'SET_PEOPLE_METADATA',
        metadata: metadata
      })
      store.commit({
        type: 'SET_PROFILE_CATEGORIES',
        metadata: metadata,
        userCategories: store.state.profileData.categories
      })
      store.commit({
        type: 'SET_PROFILE_CLIENTS',
        metadata: metadata,
        userClients: store.state.profileData.client
      })
    }

    /**
     * @description Set reorderDisabled flag
     * @param flag flag param
     */
    function setReorderDisabled (flag) {
      reorderDisabled.value = flag
    }

    /**
     * @description Emit to show or hide overlay
     * @param value Bool
     * @param path String to get path image or anything
     */
    function showBiggerPicture (value, path) {
      overlayProps.showOverlay = value
      overlayProps.path = path
    }

    /**
     * @description get data of the current profile
     * @param id current profile identifier
     */
    function getProfileData (id) {
      const resultExists = store.state.results.filter(result => result.id === id).length
      if (store.state.results.length > 0 && resultExists > 0) {
        profileIndex.value = store.state.results.findIndex(profile => profile.id === id)
      }
      fetchProfileData(id)
    }

    /**
     * @description query the information of the current profile
     * @param id current profile identifier
     */
    function fetchProfileData (id) {
      if (store.getters.isUserLogged(id)) {
        store.dispatch({
          type: 'getLoggedUserProfileInformation',
          ecCode: id
        }).then(response => {
          store.commit({
            type: 'SET_PROFILE_DATA',
            profileData: response.data.profileInformation
          })
          setPeopleMetadata(response.data.metadata)
          store.commit({
            type: 'UPDATE_PROFILE_PICTURE',
            image: response.data.profilePicture.url
          })
          store.commit({
            type: 'SET_APPROVAL_FIELDS',
            approvalFields: response.data.approvalFields
          })
          const tabPreference = response.data.settings.defaultView ? (response.data.settings.defaultView === 'About' ? 'about' : 'myPortfolio') : 'about'
          setTabActive(tabPreference)
          store.commit({
            type: 'SET_RECOVERY_INFO',
            recoveryInfo: { recoveryPhone: response.data.settings.recoveryPhone, recoveryEmail: (response.data.settings.hasRecoveryEmail === 'true' ? '***************' : '') }
          })
          completeProfileDataLoad()
        })
      } else {
        store.dispatch({
          type: 'getSearchApiPeople',
          params: {
            filters: [
              {
                field: 'id',
                values: [id]
              }
            ]
          }
        }).then(response => {
          if (response.data.value.length > 0) {
            store.commit({
              type: 'SET_PROFILE_DATA',
              profileData: response.data.value[0]
            })
            getPreferences()
          } else {
            router.push({ name: 'notFound' })
          }
        })
      }
    }

    /**
    * @description Get preferences for profile
    * @param ecCode Current profile ecCode
    */
    function getPreferences () {
      store.dispatch({
        type: 'getPreferencesByEccode',
        ecCode: route.params.id
      }).then((response) => {
        const tabPreference = response.data.defaultView ? (response.data.defaultView === 'About' ? 'about' : 'myPortfolio') : 'about'
        setTabActive(tabPreference)
        completeProfileDataLoad()
      })
    }

    /**
     * @description complete page config after profile data has been loadded
     */
    function completeProfileDataLoad () {
      store.dispatch({
        type: 'updateActiveTab',
        activeTab: store.state.profileTabActive
      })
      gotProfileData.value = true
    }

    /**
     * @description Handle back button action
     */
    function goBack () {
      if (store.state.formIsTouched) {
        return toggleDiscardBeforeLeaveModal(true)
      }
      manageBeforeUnloadListener(false)
      setScrollRestoreFlag()
      if (document.referrer && !document.referrer.includes('?token=')) {
        const prevPage = window.location.href
        window.history.go(-1)
        setTimeout(function () {
          if (window.location.href === prevPage) {
            router.push({ name: 'Home' })
          }
        }, 500)
      } else {
        store.commit({
          type: 'SET_THREE_DOTS_MENU_STATE_BY_ITEM',
          item: ''
        })
        setTabActive('about')
        if (route.params.openProfile) {
          if (route.params.k) {
            router.push({ name: 'Home', query: { p: store.state.pagination.page, k: store.state.randomOffset, r: true } })
          } else {
            const routeConfig = {
              name: 'resultsPage',
              query: {}
            }
            if (route.params.q) routeConfig.query.q = route.params.q
            if (route.params.f) routeConfig.query.f = route.params.f
            routeConfig.query.p = store.state.pagination.page
            routeConfig.query.gv = store.state.isGridView
            router.push(routeConfig)
          }
        } else {
          router.push({ name: 'Home' })
        }
      }
    }

    /**
     * @description Show & hide discardBeforeLeaveModal
     */
    function toggleDiscardBeforeLeaveModal (flag = true) {
      flag ? discardBeforeLeaveModalRef.value.open() : discardBeforeLeaveModalRef.value.close()
    }

    /**
     * @description Sets local store flag to indicate if restore or not scroll position
     */
    function setScrollRestoreFlag () {
      localStorage.setItem('restoreScrollTop', true)
    }

    /**
     * @description Load next profile, new page loaded if needed and select the first element on it
     */
    function goNext () {
      store.commit({
        type: 'SET_INITIAL_WALKTHROUGH_STATUS'
      })
      setTabActive('about')
      if (isLastProfile.value || !route.params.openProfile) return false
      if (profileIndex.value < (store.state.results.length - 1)) {
        const nextId = store.state.results[profileIndex.value + 1].id
        if (route.params.k) {
          router.push({ name: 'profile', params: { id: nextId, openProfile: true, k: route.params.k } })
        } else {
          const routeConfig = {
            name: 'profile',
            params: {}
          }
          routeConfig.params.id = nextId
          routeConfig.params.openProfile = true
          if (route.params.q) routeConfig.params.q = route.params.q
          if (route.params.f) routeConfig.params.f = route.params.f
          router.push(routeConfig)
        }
      } else {
        if (store.state.firstTime) {
          const resultsOffset = Number(store.state.randomOffset) + store.state.pagination.size
          store.commit({
            type: 'SET_RANDOM_OFFSET',
            randomOffset: Number(resultsOffset)
          })
        }
        loadNewPageProfiles(true)
      }
    }

    /**
     * @description Load previous profile, new page loaded if needed and select the last element on it
     */
    function goPrevious () {
      store.commit({
        type: 'SET_INITIAL_WALKTHROUGH_STATUS'
      })
      setTabActive('about')
      if (isFirstProfile.value || !route.params.openProfile) return false
      if (profileIndex.value > 0) {
        const previousId = store.state.results[profileIndex.value - 1].id
        if (route.params.k) {
          router.push({ name: 'profile', params: { id: previousId, openProfile: true, k: route.params.k } })
        } else {
          const routeConfig = {
            name: 'profile',
            params: {}
          }
          routeConfig.params.id = previousId
          routeConfig.params.openProfile = true
          if (route.params.q) routeConfig.params.q = route.params.q
          if (route.params.f) routeConfig.params.f = route.params.f
          router.push(routeConfig)
        }
      } else {
        if (store.state.firstTime) {
          const resultsOffset = Number(store.state.randomOffset) - store.state.pagination.size
          store.commit({
            type: 'SET_RANDOM_OFFSET',
            randomOffset: Number(resultsOffset)
          })
        }
        loadNewPageProfiles(false)
      }
    }

    /**
     * @description load profiles of a new  page
     * @param increment flag to indicate if next page is the next to the current
     */
    async function loadNewPageProfiles (increment) {
      const pageNumber = increment ? store.state.pagination.page + 1 : store.state.pagination.page - 1
      store.commit({
        type: 'SET_PAGINATION',
        size: store.state.pagination.size,
        page: pageNumber
      })
      try {
        let results = {}
        if (store.state.firstTime) {
          let resultsOffset = 0
          if (store.state.pagination.page > pageNumber) {
            resultsOffset = Number(store.state.randomOffset) - (store.state.pagination.size * (store.state.pagination.page - pageNumber))
          } else {
            resultsOffset = Number(store.state.randomOffset) + (store.state.pagination.size * (pageNumber - store.state.pagination.page))
          }
          store.commit({
            type: 'SET_RANDOM_OFFSET',
            randomOffset: Number(resultsOffset)
          })
          results = await store.dispatch({
            type: 'getRandomPeople',
            count: 48,
            k: store.state.randomOffset
          })
        } else {
          results = await store.dispatch({
            type: 'getSearchApiPeople',
            params: {
              page: store.state.pagination.page,
              ...store.state.query
            }
          })
        }
        store.dispatch({
          type: 'getPeopleMetadata',
          updateCategories: true,
          updateClients: true
        })
        store.commit({
          type: 'SET_RESULTS',
          results: results.data.value
        })
        if (increment) {
          profileIndex.value = -1
          goNext()
        } else {
          profileIndex.value = 48
          goPrevious()
        }
      } catch (error) {
        console.error('[ERROR] loadingMoreProfiles ', error)
      }
    }

    /**
     * @description Set the active tab
     * @param tabSelected Name of the tab selected
     */
    function setActiveTab (tabSelected) {
      if (store.state.profileTabActive !== tabSelected) {
        store.dispatch({
          type: 'updateActiveTab',
          activeTab: tabSelected
        })
        store.dispatch({
          type: 'getPendingTours',
          appName: 'profile'
        })
      }
      setTabActive(tabSelected)
    }

    /**
     * @description Opens modal to upload an image.
     */
    function openUploadModal () {
      saveImageModal.value.close()
      editPhotoModal.value.open()
    }

    /**
     * @description close preview modal.
     */
    function closePreviewModal () {
      previewImageModal.value.close()
      saveImageModal.value.open()
    }

    /**
     * @description Opens modal to show image uploaded preview.
     * @param {image} object with image to show.
     */
    function uploadedImage (image) {
      editPhotoModal.value.close()
      imageUploaded.value = image
      saveImageModal.value.open()
    }

    /**
     * @description Opens modal to show modified image preview.
     */
    function showPreviewImage () {
      saveImageModal.value.close()
      previewImageModal.value.open()
    }

    /**
     * @description Opens modal to notify upload process finished successfully.
     */
    function showSuccessUploadMessage () {
      emit('update-image')
      previewImageModal.value.close()
      imageSavedModal.value.open()
    }

    /**
     * @description Open collectons modal
     * @param itemsId From card
     * @param flag true or false to know if modal is open or close
     */
    function openCollectionsModal (itemsId, flag) {
      if (itemsId) addCollectionModalID.value = itemsId
      store.commit({
        type: 'SET_COLLECTION_INFO',
        itemsId: itemsId,
        flag: flag
      })
    }

    /**
     * @description Open/Close modal
     * @param flag flag to indicate if open or close
     */
    function toggleCreateCollectionsModal (flag) {
      createCollectionModalVisible.value = flag
    }

    /**
     * @description Go Back to collection Modal and reload collections
     */
    function backToCollectionModal () {
      store.dispatch({
        type: 'getCollections',
        ids: addCollectionModalID.value
      }).then(
        (response) => {
          openCollectionsModal(addCollectionModalID.value, true)
          toggleCreateCollectionsModal(false)
        }
      )
    }

    /**
     * @description Go to Create collectionModal
     */
    function forwardToCreateCollectionModal () {
      openCollectionsModal(null, false)
      toggleCreateCollectionsModal(true)
    }

    /**
     * @description Toggles visibility of settings configuration.
     * @param {isVisible} flag to indicate if settings will be visible or not.
     */
    function toggleSettingsVisibility (isVisible) {
      showSettings.value = isVisible
    }

    /**
     * @description Opens modal to confirm the item deletion.
     * @param {item} item to be deleted.
     */
    function openDeleteConfirmationModal (item) {
      deleteConfirmation.value.open()
      itemToDelete.value = item
    }

    /**
     * @description close modal to confirm the item deletion.
     */
    function closeDeleteConfirmationModal () {
      deleteConfirmation.value.close()
      itemToDelete.value = null
    }

    /**
     * @description remove item from portfolio.
     */
    function removeItem () {
      deleteConfirmation.value.close()
      myPortfolioRef.value.removeItem(itemToDelete.value)
      itemToDelete.value = null
    }

    /**
     * @description Open discard changes modal
     */
    function openDiscardChangesModal () {
      discardChangesModalRef.value.open()
    }

    /**
     * @description Close discard changes modal. Set state for unsaved changes as false
     */
    function closeForm () {
      if (store.state.sectionEdited === 'contact' || store.state.sectionEdited === 'organizational' || store.state.sectionEdited === 'social') {
        contactInformationRef.value.toggleEditionMode(store.state.sectionEdited, false)
      } else {
        detailExperienceRef.value.closeForm()
      }
      if (store.state.formIsTouched) {
        manageBeforeUnloadListener(false)
        store.commit({
          type: 'SET_FORM_TOUCHED',
          formIsTouched: false
        })
      }
    }

    /**
     * @description Adds/Removes listener for beforeunload event.
     * @param {boolean} addEvent Flag to know if listener should be added or removed.
     */
    function manageBeforeUnloadListener (addEvent = true) {
      window[addEvent ? 'addEventListener' : 'removeEventListener']('beforeunload', listenerChangePage)
    }

    /**
     * @description Listens when the users wants to change the page and shows a prevent modal.
     * @param {event} event fired by the user.
     */
    function listenerChangePage (event) {
      event.preventDefault()
      event.returnValue = ''
    }

    /**
     * @description Open api error modal
     */
    function openApiErrorModal () {
      apiErrorModalRef.value.open()
    }

    /**
     * @description Set profile tab active
     * @param tabActive Name of the tab active
     */
    function setTabActive (tabActive) {
      store.commit({
        type: 'SET_PROFILE_TAB_ACTIVE',
        profileTabActive: tabActive
      })
    }

    /**
     * @description Add listener again if needed and close the unsaved changes modal
     * @param {boolean} addEvent Flag to know if listener should be added because of a previous remove.
     */
    function cancelDiscardBeforeLeave (addEvent = false) {
      if (addEvent && store.state.formIsTouched) {
        manageBeforeUnloadListener(true)
      }
      toggleDiscardBeforeLeaveModal(false)
    }

    /**
     * @description Close form, discard changes and re try going back
     */
    function discardBeforeLeave () {
      closeForm()
      goBack()
    }

    /**
     * @description Remove listener and switch unsavedChanges flag to false
     */

    function savedChanges () {
      manageBeforeUnloadListener(false)
      if (store.state.formIsTouched) {
        store.commit({
          type: 'SET_FORM_TOUCHED',
          formIsTouched: false
        })
      }
    }

    /**
     * @description Updates the collection data to check if it contains item id
     * @param collectionIndex index of the collection to update
     */

    function updateCollectionContainId (collectionIndex) {
      store.commit({
        type: 'ADD_REMOVE_ITEM_FROM_COLLECTION',
        positionCollection: collectionIndex
      })
    }

    return {
      store,
      route,
      router,
      saveImageModal,
      editPhotoModal,
      deleteConfirmation,
      myPortfolioRef,
      discardChangesModalRef,
      contactInformationRef,
      detailExperienceRef,
      apiErrorModalRef,
      tooltipActive,
      tooltipTimeout,
      resultsOffset,
      emptyField,
      checkMobileState,
      showSettingsIcon,
      previousState,
      setReorderDisabled,
      reorderDisabled,
      showBiggerPicture,
      goBack,
      setActiveTab,
      openUploadModal,
      uploadedImage,
      showSuccessUploadMessage,
      imageSavedModal,
      openCollectionsModal,
      toggleSettingsVisibility,
      openDeleteConfirmationModal,
      closeDeleteConfirmationModal,
      removeItem,
      openDiscardChangesModal,
      closeForm,
      openApiErrorModal,
      gotProfileData,
      overlayProps,
      showSettings,
      readyProfile,
      imageUploaded,
      goPrevious,
      goNext,
      isFirstProfile,
      isLastProfile,
      previewImageModal,
      showPreviewImage,
      closePreviewModal,
      profileContentRef,
      containerInfoScrollTop,
      backToCollectionModal,
      forwardToCreateCollectionModal,
      addCollectionModalID,
      backLabel,
      discardBeforeLeaveModalRef,
      discardBeforeLeave,
      toggleDiscardBeforeLeaveModal,
      manageBeforeUnloadListener,
      listenerChangePage,
      cancelDiscardBeforeLeave,
      savedChanges,
      toggleCreateCollectionsModal,
      createCollectionModalVisible,
      updateCollectionContainId,
      scrollToTop,
      scrollY
    }
  }
}
</script>
