<template>
  <section class="containerSaveImage">
    <section class="containerSaveImage__imageContent">
      <div id="mainCropper"></div>
    </section>
    <section class="containerSaveImage__buttons">
      <button class="containerSaveImage__buttons--back" @click="back">BACK</button>
      <button class="containerSaveImage__buttons--preview" @click="preview">CONTINUE</button>
    </section>
  </section>
</template>

<script>
import { onMounted, onUnmounted, ref } from 'vue'
import { useStore } from 'vuex'
import Croppie from 'croppie'
import 'croppie/croppie.css'

export default {
  name: 'saveImage',
  props: {
    image: {
      required: true
    }
  },
  emits: ['back', 'showPreviewImage'],
  setup (props, { emit }) {
    const store = useStore()
    const croppieImage = ref(null)

    onMounted(() => {
      setupCrop()
    })

    onUnmounted(() => {
      croppieImage.value.destroy()
    })

    /**
     * @description Set up crop image plugin
     */
    function setupCrop () {
      croppieImage.value = new Croppie(document.getElementById('mainCropper'), {
        viewport: { width: 220, height: 220, type: 'circle' },
        boundary: { width: 240, height: 240 },
        showZoomer: true
      })
      croppieImage.value.bind({
        url: props.image.src
      })

      const zoomSpan = document.createElement('span')
      zoomSpan.setAttribute('class', 'cr-slider-label')
      document.getElementsByClassName('cr-slider-wrap')[0].appendChild(zoomSpan)

      const rangeInput = document.getElementsByClassName('cr-slider')[0]
      rangeInput.addEventListener('input', handleInputChange)

      const crViewPort = document.getElementsByClassName('cr-overlay')[0]
      crViewPort.addEventListener('wheel', handleInputChange)
    }

    /**
     * @description set custom style when input range change
     */
    function handleInputChange () {
      setTimeout(() => {
        const target = document.getElementsByClassName('cr-slider')[0]
        const min = target.min
        const max = target.max
        const val = target.value
        target.style.backgroundSize = (val - min) * 100 / (max - min) + '% 100%'
      })
    }

    /**
     * @description Back previuos step (User get to upload image again)
     */
    function back () {
      emit('back')
    }

    /**
     * @description Preview cropped and resized image
     */
    function preview () {
      croppieImage.value.result('base64').then(function (base64Image) {
        store.commit({
          type: 'SET_BASE64_SRC_IMAGE',
          base64SrcImage: base64Image
        })
        emit('showPreviewImage')
      })
    }

    return {
      store,
      back,
      preview
    }
  }
}
</script>
