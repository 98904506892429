<template>
  <section class="containerContactInfoField">
    <span class="containerContactInfoField__title">{{ title }}</span>
    <span v-if="!field && showEmptyMessage" class="containerContactInfoField__label notSpecified">Not specified</span>
    <section :class="{'pendingApprovalContainer': pendingApproval}" v-else>
      <span v-if="fieldType === 'label'" class="containerContactInfoField__label">{{ field }}</span>
      <a v-else-if="fieldType === 'link'" :href="link" target="_self" class="containerContactInfoField__label link"
        @mouseover="openPopupPersonalInfo($event, true)"
        @mouseleave="openPopupPersonalInfo($event, false)"
        :title="toolTipInformation"
        :id="ecCode ? 'link': ''"
      >
        <span v-if="icon" :class="icon" class="containerContactInfoField__icon"></span>{{ field }}
        <popup-personal-info-generic v-if="ecCode && openPopup" :popupPosition="popupPosition" :personalInfoProp="personalInfoProp" :profileLink="link" @click.stop.prevent></popup-personal-info-generic>
      </a>
      <span v-if="pendingApproval" class="pendingApprovalContainer__warningIcon grey-icon-warning-24px" title="There is a change pending approval"></span>
    </section>
  </section>
</template>

<script>
import { computed, reactive, ref } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'contactInformationField',
  props: {
    title: {
      type: String,
      required: true
    },
    field: {
      type: String,
      required: true
    },
    link: {
      type: String
    },
    showEmptyMessage: {
      type: Boolean,
      default: true
    },
    fieldType: {
      type: String,
      required: true,
      validator: (type) => ['label', 'link'].includes(type)
    },
    icon: {
      type: String,
      required: false
    },
    pendingApproval: {
      type: Boolean,
      default: false
    },
    ecCode: {
      type: String,
      required: false,
      default: ''
    },
    showToolTip: {
      type: Boolean,
      default: true
    }
  },
  setup (props) {
    const store = useStore()
    const openPopup = ref(false)
    const popupPosition = reactive({
      right: 0,
      top: 0,
      bottom: 0
    })
    const requestControl = ref(null)

    /**
     * @description Returns the string to be shown in the tooltip.
     */
    const toolTipInformation = computed(() => {
      return props.showToolTip ? props.field : null
    })

    /**
     * @description Returns the personal info to be shown in the Popup Personal Info component
     */
    const personalInfoProp = computed(() => {
      return store.state.managerInfo
    })

    /**
     * @description Open and positioned popup depending coordenates to cursor and space free
     * @param event of DOM
     * @param {Bool} flag to show or hide modal
     */
    function openPopupPersonalInfo (event, flag) {
      const link = document.getElementById('link')

      if (!link) {
        return
      }

      const rect = link.getBoundingClientRect()
      const bottomSpace = window.innerHeight - rect.bottom
      const rightSpace = window.innerWidth - rect.right
      const topSpace = rect.top

      if (!navigator.userAgent.match(/Android|iPhone|iPad/i)) {
        clearTimeout(requestControl.value)
        requestControl.value = setTimeout(() => {
          popupPosition.right = `${event.clientX - rect.left + 10}px`
          if (bottomSpace > 210 && rightSpace > 430) {
            openPopup.value = flag
            popupPosition.top = '100%'
            popupPosition.bottom = 'none'
          } else if (rightSpace > 430 && topSpace > 210) {
            openPopup.value = flag
            popupPosition.bottom = '100%'
            popupPosition.top = 'none'
          } else if (bottomSpace < 200 && rightSpace < 575) {
            openPopup.value = flag
            popupPosition.right = '-50px'
            popupPosition.bottom = '100%'
            popupPosition.top = 'none'
          } else {
            openPopup.value = flag
            popupPosition.right = '-50px'
            popupPosition.top = '100%'
          }
        }, 500)
      }
    }

    return {
      store,
      toolTipInformation,
      openPopupPersonalInfo,
      openPopup,
      popupPosition,
      personalInfoProp
    }
  }
}
</script>
