<template>
  <section class="editableInputContainer">
    <div class="editableInputContainer__editableTextField"
          :class="[isAnError ? 'errorMessage': '', focusEnabled ? 'focusEnabled' : '']"
          :contenteditable="contentEditable"
          @input="updateInputValue"
          @focus="focusEnabled = true"
          @blur="focusEnabled = false"
          :placeholder="placeHolder ? placeHolder : ''"
          ref="editableInputRef">{{ editableFieldValue }}</div>
    <div class="editableInputContainer__aditionalInfo">
      <span class="editableInputContainer__aditionalInfo--instructions" v-if="instructions">{{instructions}}</span>
      <span class="editableInputContainer__aditionalInfo--charCounter" v-if="hasCharCounter">characters count: {{charactersCounter}} </span>
    </div>
  </section>
</template>

<script>
import { onMounted, ref } from 'vue'
export default {
  name: 'editableInput',
  props: {
    isAnError: {
      type: Boolean,
      default: false
    },
    placeHolder: {
      type: String,
      required: false,
      default: null
    },
    instructions: {
      type: String,
      required: false,
      default: null
    },
    focus: {
      type: Boolean,
      default: false
    },
    hasCharCounter: {
      type: Boolean,
      default: true
    },
    contentEditable: {
      type: Boolean,
      default: true
    },
    modelValue: {}
  },
  emits: ['update:modelValue', 'unsavedChanges'],
  setup (props, { emit }) {
    const editableInputRef = ref(null)
    const editableFieldValue = ref(null)
    const charactersCounter = ref(null)
    const focusEnabled = ref(false)

    onMounted(() => {
      editableFieldValue.value = props.modelValue
      charactersCounter.value = props.modelValue.length
      if (props.focus) {
        editableInputRef.value.focus()
      }
    })

    /**
     * @description Propagates event to notify the input value has changed.
     */
    function updateInputValue () {
      charactersCounter.value = editableInputRef.value.textContent.length
      emit('unsavedChanges')
      emit('update:modelValue', editableInputRef.value.innerText)
    }

    return {
      updateInputValue,
      editableInputRef,
      editableFieldValue,
      charactersCounter,
      focusEnabled
    }
  }
}
</script>
