<template>
  <section class="containerHighlightedBox containerHighlightedBox__background"></section>
</template>

<script>
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watchEffect } from 'vue'

export default {
  name: 'highlightedBox',
  props: {
    queryElementToHighLight: {
      type: String,
      required: true
    },
    stepNumber: {
      type: Number,
      default: 0
    },
    activeStepNumber: {
      type: Number,
      default: 0
    }
  },
  emits: ['renderFinish'],
  setup (props, { emit }) {
    const cloneReference = ref(null)
    const parentNode = ref(null)

    onMounted(() => {
      cloneComponent()
      updateOpacity()
      nextTick(() => {
        emit('renderFinish')
      })
    })

    onBeforeUnmount(() => {
      updateOpacity(0)
      deleteClone()
    })

    /**
     * @description Retusn a flag indicating if the current highlighted box is active or not.
     */
    const isActive = computed(() => {
      return props.stepNumber === props.activeStepNumber
    })

    watchEffect(() => props.activeStepNumber, () => {
      updateOpacity()
    })

    function updateOpacity (opacity) {
      document.getElementById('app').style.opacity = opacity === undefined ? isActive.value ? '1' : '0' : opacity
      changeZIndexHighlightedElement()
    }

    /**
     * @description Creates a clone of the highlight component to be used as a mask and avoid any click on it.
     */
    function cloneComponent () {
      cloneReference.value = document.getElementById('app').cloneNode(true)
      cloneReference.value.classList.add('containerHighlightedBox__mask')
      parentNode.value = document.getElementById('app').parentNode
      parentNode.value.appendChild(cloneReference.value)
    }

    /**
     * @description Changes the value of z-index of element to be highligthed to show it over the overlay.
     */
    function changeZIndexHighlightedElement () {
      const functionToCallOnElement = isActive.value ? 'remove' : 'add'
      const functionToCallOnHighligthtedElement = isActive.value ? 'add' : 'remove'
      cloneReference.value.classList[functionToCallOnElement]('showUnderTheOverlay')
      document.getElementById('app').classList[functionToCallOnElement]('showUnderTheOverlay')
      parentNode.value.querySelectorAll(props.queryElementToHighLight).forEach(element => {
        element.classList[functionToCallOnHighligthtedElement]('showOverTheOverlay')
      })
    }

    /**
     * @description Removes the clone when the component dissapears.
     */
    function deleteClone () {
      cloneReference.value.parentNode.removeChild(cloneReference.value)
      parentNode.value.querySelectorAll(props.queryElementToHighLight).forEach(element => {
        element.classList.remove('showOverTheOverlay')
      })
    }

    return {
      isActive
    }
  }
}
</script>
