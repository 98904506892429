<template>
  <transition
    enter-active-class="overlayAnimationIn"
    leave-active-class="overlayAnimationOut"
  >
    <section class="containerOverlay"
      @click="closeOverlay">
      <img class="containerOverlay__image" :src="config.path">
    </section>
  </transition>
</template>

<script>
import { useStore } from 'vuex'

export default {
  name: 'overlay',
  props: {
    config: {
      type: Object,
      required: true
    }
  },
  emits: ['closeOverlay'],
  setup (props, { emit }) {
    const store = useStore()

    function closeOverlay () {
      emit('closeOverlay', false, '')
    }

    return {
      store,
      closeOverlay
    }
  }
}
</script>

<style src="./overlay.scss" lang="scss"></style>
