<template>
  <section class="containerProfileContactPills">
    <profileContactPill
      :field="store.state.profileData.email"
      icon="grey-icon-profile-email"
      :stickyHeader="stickyHeader"
      @contactPillAction="emailToProfile">
    </profileContactPill>
    <profileContactPill
      :field="msTeamsLabel"
      icon="grey-icon-msteams"
      :stickyHeader="stickyHeader"
      @contactPillAction="msTeamsToProfile">
    </profileContactPill>
    <profileContactPill
      propertyToUpdate='mobile_phone'
      :field="store.state.profileData.mobile_phone"
      icon="grey-icon-profile-mobile"
      :stickyHeader="stickyHeader"
      @contactPillAction="phoneCallToProfile(store.state.profileData.mobile_phone)"
      editable
    >
    </profileContactPill>
    <profileContactPill
      :field="store.state.profileData.phone"
      icon="grey-icon-profile-phone"
      :stickyHeader="stickyHeader"
      @contactPillAction="phoneCallToProfile(store.state.profileData.phone)"
      v-if="store.state.profileData.phone"
    >
    </profileContactPill>
    <profileContactPill
      field="Recovery Information"
      icon="grey-icon-recovery"
      title="This info will be used for password recovery"
      v-if="store.getters.isUserLogged() && !stickyHeader"
      editable
      :multipleModal="true"
      class="profileContactPill--recovery"
    >
    </profileContactPill>
  </section>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import profileContactPill from '@/components/profileContactPill/profileContactPill'

export default {
  name: 'profileContactPills',
  props: {
    stickyHeader: {
      type: Boolean,
      default: false
    }
  },
  components: {
    profileContactPill
  },
  setup (props) {
    const store = useStore()

    /**
     * @description Get MS teams pill label
     */
    const msTeamsLabel = computed(() => {
      return store.state.profileData.email ? 'MS Teams' : ''
    })

    /**
     * @description Open email client to send email to user
     */
    function emailToProfile () {
      window.open('mailto:' + store.state.profileData.email, '_self')
    }

    /**
     * @description Open MS teams to talk to user
     */
    function msTeamsToProfile () {
      window.open(`https://teams.microsoft.com/l/chat/0/0?users=${store.state.profileData.email}`, '_blank')
    }

    /**
     * @description Make a phone call to user
     * @param phoneNumber Number of phone
     */
    function phoneCallToProfile (phoneNumber) {
      window.location = `tel:${phoneNumber}`
    }

    return {
      store,
      msTeamsLabel,
      emailToProfile,
      msTeamsToProfile,
      phoneCallToProfile
    }
  }
}
</script>
