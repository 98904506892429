<template>
  <span class="containerGenericPill">
    <span class="grey-icon-close containerGenericPill__closeBtn" @click.stop="remove"></span>
    <span class="containerGenericPill__label">{{label}}</span>
  </span>
</template>

<script>
export default {
  name: 'pill',
  props: {
    label: {
      required: true,
      type: String
    }
  },
  emits: ['remove'],
  setup (props, { emit }) {
    /**
     * @description Emits an event of remove value related to the pill.
     */
    function remove () {
      emit('remove')
    }

    return {
      remove
    }
  }
}
</script>
