<template>
  <section class="containerFirstPage" ref="containerFirstPage">
    <section class="containerFirstPage__profileSettings">
      <span class="containerFirstPage__profileSettings--title">{{messages.title}}</span>
    </section>
    <section class="containerFirstPage__options">
      <span class="containerFirstPage__options--title">{{messages.subTitle}}</span>
      <div class="containerFirstPage__options--input" :class="{'noBottomBorders': showOptions}" @click="openOptions($event, true)">
        <input class="input"
          ref="inputDropDown"
          :value="valueSelected"
          readonly
          @blur="openOptions($event, false)"
          @keydown ="keyUpHandler($event)"
        >
      </div>
      <span class="containerFirstPage__options--info">{{messages.instructions}}</span>
    </section>
    <transition enter-active-class="optionsAnimationIn">
      <section class="containerFirstPage__optionsList" v-if="showOptions" :style="{'top': topPosition}">
        <ul class="containerFirstPage__optionsList--list">
          <li v-for="(item, index) in messages.options"
            :key="index"
            class="itemList"
            @mousedown="selectOption(item.name)"
            :class="[temporarySelectionIndex === index ? 'selected' : '', hovered && temporarySelectionIndex === index ? 'hovered' : '']"
            @mouseover="activeHovered(index)"
          >
            {{ item.name }}
          </li>
        </ul>
      </section>
    </transition>
  </section>
</template>

<script>
import { reactive, ref } from 'vue'
import { useStore } from 'vuex'
import messagesConfig from './firstPage.json'

export default {
  name: 'firstPage',
  props: {
    defaultView: {
      type: String,
      required: true,
      default: 'About'
    }
  },
  setup (props) {
    const store = useStore()
    const messages = reactive(messagesConfig)
    const showOptions = ref(false)
    const valueSelected = ref(props.defaultView === 'About' ? 'About Me' : props.defaultView)
    const temporarySelectionIndex = ref(props.defaultView === 'About' ? 0 : 1)
    const hovered = ref(false)
    const topPosition = ref('')
    const inputDropDown = ref(null)
    const containerFirstPage = ref(null)

    /**
     * @description Open or hide option menu
     * @param event of DOM
     * @param {Bool} flag
     */
    function openOptions (event, flag) {
      topPosition.value = `${inputDropDown.value.getBoundingClientRect().bottom - containerFirstPage.value.getBoundingClientRect().top}px`
      showOptions.value = flag
    }

    /**
     * @description Selected option
     * @param option Value user has selected
     */
    function selectOption (option) {
      if (valueSelected.value !== option) {
        valueSelected.value = option
        savePreferences(valueSelected.value === 'About Me' ? 'About' : valueSelected.value)
      } else {
        openOptions(event, false)
      }
    }

    /**
     * @description Manages logic of key pressed and based on user key pressed selects function
     * to execute.
     * @param event event send by user at moment of press a key.
     */
    function keyUpHandler (event) {
      event.stopPropagation()
      event.stopImmediatePropagation()
      isHover(false)

      switch (event.key) {
        case 'Enter':
          selectOption(messages.options[temporarySelectionIndex.value].name)
          break
        case 'ArrowUp':
          moveSelection('up')
          break
        case 'ArrowDown':
          moveSelection('down')
          break
      }
    }

    /**
     * @description Navigates throught the list of items using arrows of  the keyboard.
     * @param direction direction of the movement to navigate.
     */
    function moveSelection (direction) {
      if (direction === 'up') {
        temporarySelectionIndex.value--
      }
      if (direction === 'down') {
        temporarySelectionIndex.value++
      }

      if (temporarySelectionIndex.value >= messages.options.length) {
        resetTemporarySelection()
      }

      if (temporarySelectionIndex.value < 0) {
        temporarySelectionIndex.value = messages.options.length - 1
      }
    }

    /**
     * @description Sets the index to track selected option at the begining.
     */
    function resetTemporarySelection () {
      temporarySelectionIndex.value = 0
    }

    /**
     * @description Active hover and disable keyword press desing
     * @param index Position of array selected by user
     */
    function activeHovered (index) {
      temporarySelectionIndex.value = index
      isHover(true)
    }

    /**
     * @description Enable hover
     * @param {Bool} flag
     */
    function isHover (flag) {
      hovered.value = flag
    }

    /**
     * @description Save preferences by user
     * @param defaultView default view user choose
     */
    function savePreferences (defaultView) {
      store.dispatch({
        type: 'savePreferences',
        data: { defaultView: defaultView }
      }).then(() => {
        openOptions(event, false)
      })
    }

    return {
      store,
      inputDropDown,
      containerFirstPage,
      keyUpHandler,
      activeHovered,
      messages,
      valueSelected,
      showOptions,
      openOptions,
      topPosition,
      temporarySelectionIndex,
      hovered,
      selectOption
    }
  }
}
</script>
