<template>
  <section class="containerMediaIcon">
    <a v-if="link" target="blank" :href="link" class="containerMediaIcon__activeIcon" :class="className"></a>
    <span v-else class="containerMediaIcon__inactiveIcon" :class="className"/>
  </section>
</template>

<script>
export default {
  props: {
    link: {
      type: String
    },
    className: {
      type: String,
      required: true
    }
  }
}
</script>
