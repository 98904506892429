<template>
  <section>
    <form class="containerContactForm">
      <section
        class="containerContactForm__containerInput"
        v-for="(field, index) in fields"
        :key="index"
        :class="{error : isInvalidField(field) && validated}"
      >
        <label
          class="containerContactForm__containerInput--label"
          :class="{'required': field.required, optional: !field.required && !field.disabled}"
        >
          {{ field.label }}
        </label>
        <inputList
          :optionList="field.options"
          v-model="field.model"
          v-if="field.type && field.type === 'list'"
          :optionsPromise="field.optionsPromise"
          class="containerContactForm__containerInput--list"
          @touched="markFormAsTouched(field)"
        />
        <input
          v-else
          :disabled="field.disabled"
          v-model="field.model"
          @input="markFormAsTouched(field)"
          class="containerContactForm__containerInput--input"
          :maxlength="field.maxlength"
          :placeholder="field.placeholder ? field.placeholder : ''"
        />
        <span class="containerContactForm__containerInput--requiredError" >This field cannot be empty</span>
      </section>
      <section class="containerContactForm__editButtons">
        <button @click="validateForm(true)" class="editButtons__cancel" type="button" :disabled="store.state.savingProfile">CANCEL</button>
        <button @click="saveForm" class="editButtons__save" type="button" :disabled="store.state.savingProfile">SAVE</button>
      </section>
    </form>
  </section>
</template>

<script>
import { useStore } from 'vuex'
import inputList from '@/components/inputList/inputList'
import { onMounted, ref } from 'vue'

export default {
  name: 'contactInformationForm',
  components: {
    inputList
  },
  props: {
    fields: {
      type: Array
    },
    id: {
      type: String,
      required: true
    },
    approvalFields: {
      type: Boolean,
      required: false
    }
  },
  emits: ['openApiErrorModal', 'openDiscardChangesModal', 'close', 'informTouchOnForm', 'formSaved'],
  setup (props, { emit }) {
    const store = useStore()
    const validated = ref(false)
    const isFormTouched = ref(false)

    onMounted(() => {
      if (store.state.isPhone) {
        document.querySelector('.editExperienceMobileContainer').scrollTop = 0
      }
    })

    /**
     * @description Saves the conent of the form.
     */
    function saveForm () {
      validated.value = true
      const body = buildBody()

      if (!body) {
        validateForm(false)
        return
      }

      if (isAValidForm()) {
        store.dispatch({
          type: 'updateProfile',
          body: body
        }).then(() => {
          if (props.approvalFields) {
            store.dispatch({
              type: 'getApprovalFields',
              ecCode: props.id
            }).then(() => {
              validateForm(false)
            })
          } else {
            validateForm(false)
          }
          emit('formSaved')
        }).catch(() => {
          emit('openApiErrorModal')
        })
      }
    }

    /**
     * @description Builds a body object to make a request to update a profile section.
     */
    function buildBody () {
      const body = props.fields.reduce((objBody, property) => {
        if (!property.disabled) {
          if (property.propertyToSend && property.model) {
            objBody[property.field] = property.model[property.propertyToSend] || property.defaultValue
          } else {
            objBody[property.field] = property.type === 'list' ? [property.model] : property.model
          }
        }
        return objBody
      }, {})

      if (Object.keys(body).length === 0) {
        return null
      }

      body.id = props.id
      body.facebook = (body.facebook && body.facebook === 'https://facebook.com/') ? '' : body.facebook
      body.instagram = (body.instagram && body.instagram === 'https://instagram.com/') ? '' : body.instagram
      body.twitter = (body.twitter && body.twitter === 'https://twitter.com/') ? '' : body.twitter
      body.linkedin = (body.linkedin && body.linkedin === 'https://linkedin.com/') ? '' : body.linkedin
      return body
    }

    /**
     * @description Validates the form previous to save it.
     */
    function isAValidForm () {
      const isInvalid = props.fields.some(field => {
        return isInvalidField(field)
      })
      return !isInvalid
    }

    /**
     * @description Indicates if the field is an invalid one.
     * @param {field} object with field information.
     */
    function isInvalidField (field) {
      if (field.disabled) {
        return false
      } else {
        if (field.required) {
          if (field.propertyToSend) {
            return field.model && field.model[field.propertyToSend] ? !field.model[field.propertyToSend].trim() : !field.model || !field.model.trim()
          } else {
            return field.model ? !field.model.trim() : true
          }
        }
        return false
      }
    }

    /**
     * @description Marks form as touched.
     */
    function markFormAsTouched (field) {
      field.touched = true
      isFormTouched.value = true
      emit('informTouchOnForm')
    }

    /**
     * @description Closes the form.
     * @param {openModal} boolean indicating if modal to prevent discard changes should be shown.
     */
    function validateForm (openModal) {
      if (openModal && isFormTouched.value) {
        emit('openDiscardChangesModal')
      } else {
        emit('close')
      }
    }

    return {
      store,
      saveForm,
      markFormAsTouched,
      isInvalidField,
      validateForm,
      validated
    }
  }
}
</script>
