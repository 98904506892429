<template>
  <div class="skeletonProfileContainer">
    <div class="skeletonProfileContainer__header">
      <div class="skeletonProfileContainer__header--img"></div>
      <div class="skeletonProfileContainer__header--info">
        <div class="name">
          <div class="name__line"></div>
          <span class="grey-icon-show-more"></span>
        </div>
        <div class="data">
          <div class="data__element" v-for="element in repeatThree" :key="element"></div>
        </div>
        <div class="contact">
          <div class="contact__element" v-for="element in repeatFour" :key="element"></div>
        </div>
      </div>
    </div>
    <div class="skeletonProfileContainer__body">
      <div class="skeletonProfileContainer__body--tabs">
        <span class="tab tabActive">About Me</span>
        <span class="tab">My Portfolio</span>
      </div>
      <div class="skeletonProfileContainer__body--sections">
        <div class="organizationSection">
          <div class="organizationSection__title"></div>
          <div class="organizationSection__data" v-for="element in repeatEight" :key="element">
            <div class="organizationSection__data--label"></div>
            <div class="organizationSection__data--info"></div>
          </div>
          <div class="organizationSection__title"></div>
          <div class="organizationSection__social">
            <div class="organizationSection__social--icon" v-for="element in repeatFour" :key="element"></div>
          </div>
        </div>
        <div class="experienceSection" v-if="!isMobile">
          <div class="experienceSection__field" v-for="field in experienceFields" :key="field">
            <div class="experienceSection__field--title"></div>
            <div class="experienceSection__field--data" v-for="(subfield, index) in field.subfields" :key="subfield">
              <div class="label"></div>
              <div class="textLine" v-for="textLine in field.subfields[index].textLines" :key="textLine"></div>
              <div class="pill" v-for="pill in field.subfields[index].pills" :key="pill"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'

export default {
  name: 'skeletonProfile',
  setup () {
    const repeatThree = ref(3)
    const repeatFour = ref(4)
    const repeatEight = ref(8)
    const isMobile = ref(false)
    const experienceFields = ref([{
      subfields: [{
        textLines: 13,
        pills: 0
      }, {
        textLines: 7,
        pills: 0
      }, {
        textLines: 5,
        pills: 0
      }, {
        textLines: 0,
        pills: 4
      }, {
        textLines: 0,
        pills: 4
      }, {
        textLines: 1,
        pills: 0
      }]
    }, {
      subfields: [{
        textLines: 0,
        pills: 5
      }, {
        textLines: 0,
        pills: 3
      }, {
        textLines: 1,
        pills: 0
      }]
    }, {
      subfields: [{
        textLines: 0,
        pills: 2
      }, {
        textLines: 1,
        pills: 0
      }]
    }, {
      subfields: [{
        textLines: 1,
        pills: 0
      }, {
        textLines: 0,
        pills: 4
      }, {
        textLines: 1,
        pills: 0
      }]
    }])

    onMounted(() => {
      resizeSetUp()
      handleResize()
    })

    /**
     * @description Handler to know the size of the screen
     */
    function handleResize () {
      window.addEventListener('resize', function (e) {
        resizeSetUp()
      })
    }

    /**
    * @description Handler to know the size of the screen
    */
    function resizeSetUp () {
      if ((window.innerWidth < 576) && (window.innerWidth > 0)) {
        isMobile.value = true
      } else {
        isMobile.value = false
      }
    }

    return {
      repeatThree,
      repeatFour,
      repeatEight,
      experienceFields,
      isMobile
    }
  }
}
</script>
