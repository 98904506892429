<template>
  <section class="containerDropDown" v-click-away="closeOptions" ref="dropdown">
    <section class="containerDropDown__containerInput" :class="[isAnError ? 'errorMessage': '', disabled ? 'disabled' : '', focusEnabled ? 'focusEnabled' : '']">

      <!-- For cases where is necesary to add multiple values to the input -->
      <section class="containerDropDown__containerInput--pills">
        <span v-for="(item, index) in optionsSelected" :key="index" class="pill">
          <span class="grey-icon-close pill__closeButton" @click="removeItem(index)"></span>
          <span class="pill__label">{{item.value}}</span>
        </span>
        <input
          class="multipleInput"
          :placeholder="placeholder"
          :value="optionSelected.value"
          @input="updateInput($event)"
          :readonly="isReadOnlyInput"
          @focus="openOptions($event)"
          @keydown ="keyUpHandler($event)"
          @blur="closeOptions(true)"
          ref="multipleInput"
        />
      </section>

      <span v-if="isArrowVisible" @click="openOptions($event)">
        <!-- <md-icon class="containerDropDown__containerInput--arrow">arrow_drop_down</md-icon> -->
      </span>
    </section>
    <transition enter-active-class="optionsAnimationIn">
      <section class="containerDropDown__results" v-if="showOptionList" ref="listContainer">
        <ul ref="list">
          <li
            v-for="(item, index) in optionsToShow"
            :key="index"
            :data-index="index"
            :class="[temporarySelectionIndex === index ? 'selected' : '']"
            class="containerDropDown__results--option"
            @mousedown="selectOption(item, true)"
          >
            {{item.value}}
          </li>
        </ul>
      </section>
    </transition>
    <div class="containerDropDown__aditionalInfo">
      <span class="containerDropDown__aditionalInfo--instructions" v-if="instructions">{{instructions}}</span>
    </div>
  </section>
</template>

<script>
import { useStore } from 'vuex'
import { computed, nextTick, onMounted, reactive, ref, watch } from 'vue'

export default {
  name: 'dropdownInput',
  props: {
    isArrowVisible: {
      type: Boolean,
      default: true
    },
    options: {
      type: Array,
      default () {
        return []
      }
    },
    userValuesAllowed: {
      type: Boolean,
      default: false
    },
    isReadOnlyInput: {
      type: Boolean,
      default: false
    },
    isAnError: {
      type: Boolean,
      default: false
    },
    modelValue: {},
    disabled: {
      type: Boolean,
      default: false
    },
    showUniqueValues: {
      type: Boolean,
      default: true
    },
    instructions: {
      type: String,
      required: false,
      default: null
    }
  },
  emits: ['unsavedChanges', 'update:modelValue', 'updated'],
  setup (props, { emit }) {
    const store = useStore()
    const showOptions = ref(false)
    const optionSelected = reactive({
      id: null,
      value: null
    })
    const optionsSelected = ref([])
    const optionsToShow = ref([])
    const optionSelectedByUser = ref(false)
    const temporarySelectionIndex = ref(0)
    const initialValueLoaded = ref(false)
    const placeholder = ref('Start typing...')
    const multipleInput = ref(null)
    const dropdown = ref(null)
    const listContainer = ref(null)
    const list = ref(null)
    const focusEnabled = ref(false)

    onMounted(() => {
      loadInitalList()
      setDefaultValue()
    })

    /**
     * @description Returns a flag indicating if list of options have to be shown or not.
     */
    const showOptionList = computed(() => {
      if (showOptions.value && optionsToShow.value) {
        return showOptions.value && optionsToShow.value.length > 0
      }
      return false
    })

    /**
     * @description Updates value usign the one passed by parent component, only works for the first
     * assigment to avoid circular calls.
     * @param newValue current value of input.
     * @param oldValue previous value of input.
     */
    watch(() => props.modelValue, (newValue, oldValue) => {
      emit('updated', newValue)
      if (oldValue && newValue && oldValue.id === newValue.id) {
        return
      }
      setDefaultValue()
      if (!newValue) {
        resetInput()
      }
    })

    /**
     * @description Updates options to show async.
     * @param newValue current value of options.
     * @param oldValue previous value of options.
     */
    watch(() => props.options, (newValue, oldValue) => {
      validateOptions()
    })

    /**
     * @description Adds a selected option into the array of selected options.
     * @param selectedOption option selected by the user.
     */
    function addOptionToSelectedOptions (selectedOption) {
      const isValueAlreadyAdded = optionsSelected.value.some((option) => {
        return option.value.toString().toLowerCase() === selectedOption.value.toString().toLowerCase()
      })

      if (!isValueAlreadyAdded || (isValueAlreadyAdded && !props.showUniqueValues)) {
        optionsSelected.value.push(Object.assign({}, selectedOption))
      }
      setPlaceholder()
    }

    /**
     * @description Closes options of dropdown.
     * @param clearInput boolean to indicate if not only options should be close, but also input
     * should be cleared.
     */
    function closeOptions (clearInput) {
      if (clearInput) focusEnabled.value = false
      if (!showOptions.value) {
        return
      }
      setPlaceholder()
      showOptions.value = false
      if (clearInput && !optionSelectedByUser.value) {
        setValueEnterByUser()
        resetInput()
      }
    }

    /**
     * @description Manages logic of key pressed and based on user key pressed selects function
     * to execute.
     * @param event event send by user at moment of press a key.
     */
    function keyUpHandler (event) {
      event.stopPropagation()
      event.stopImmediatePropagation()

      if (props.isReadOnlyInput) {
        return
      }
      switch (event.key) {
        case 'Enter':
          setValueSelectedByUser()
          break
        case 'ArrowUp':
          moveSelection('up')
          break
        case 'ArrowDown':
          moveSelection('down')
          break
        case 'Tab':
          if (props.userValuesAllowed && optionSelected.value) {
            event.preventDefault()
            setValueEnterByUser()
            nextTick(() => {
              multipleInput.value.focus()
            })
          }
          break
        case ' ':
        case ',':
        case ';':
          if (props.userValuesAllowed && multipleInput.value.value !== ' ') {
            event.preventDefault()
            setValueEnterByUser()
          }
          break
        case 'Backspace':
          if (optionsSelected.value.length > 0 && !optionSelected.value) {
            removeItem(optionsSelected.value.length - 1)
            setPlaceholder()
          } else {
            setTimeout(() => {
              launchSearch()
            })
          }
          break
        default:
          setTimeout(() => {
            launchSearch()
          })
      }
    }

    /**
     * @description Launches a search usign the input value as search term.
     */
    function launchSearch () {
      resetTemporarySelection()
      markInputAsInvalidOption()
      loadInitalList()

      if (!optionSelected.value) {
        return
      }
      if (props.showUniqueValues) {
        optionsToShow.value = optionsToShow.value.filter(option => {
          return option.value.toString().toLowerCase().indexOf(optionSelected.value.toLowerCase().trimLeft().trimRight()) !== -1
        })
      }
      showOptions.value = true
    }

    /**
     * @description Populates list of options with initial data.
     */
    function loadInitalList () {
      if (props.options) {
        if (optionsSelected.value.length > 0) {
          if (props.showUniqueValues) {
            optionsToShow.value = props.options.filter((option) => {
              return !optionsSelected.value.find(optionSelected => {
                return optionSelected.id === option.id || optionSelected.value === option.value
              })
            })
          } else {
            const indexToSkip = []
            optionsSelected.value.forEach(optionSelected => {
              const index = props.options.findIndex((option, index) => {
                return !indexToSkip.some(i => i === index) && (optionSelected.id === option.id || optionSelected.value === option.value)
              })
              if (index !== -1) {
                indexToSkip.push(index)
              }
            })
            optionsToShow.value = props.options.filter((option, index) => !indexToSkip.some(i => i === index))
          }
        } else {
          optionsToShow.value = props.options.slice(0)
        }
      }
    }

    /**
     * @description Marks the value of the input as an invalid option to be added.
     */
    function markInputAsInvalidOption () {
      optionSelectedByUser.value = false
    }

    /**
     * @description Marks the value of the input as a valid option to be added.
     */
    function markInputAsValidOption () {
      optionSelectedByUser.value = true
    }

    /**
     * @description Navigates throught the list of items using arrows of  the keyboard.
     * @param direction direction of the movement to navigate.
     */
    function moveSelection (direction) {
      if (direction === 'up') {
        temporarySelectionIndex.value--
      }
      if (direction === 'down') {
        temporarySelectionIndex.value++
      }

      if (temporarySelectionIndex.value >= optionsToShow.value.length) {
        resetTemporarySelection()
      }

      if (temporarySelectionIndex.value < 0) {
        temporarySelectionIndex.value = optionsToShow.value.length - 1
      }

      if (showOptionList.value) {
        nextTick(() => {
          scrollToOption()
        })
      }
    }

    /**
     * @description Opens options of dropdown.
     */
    function openOptions () {
      focusEnabled.value = true
      if (!props.options || props.options.length < 1) {
        return
      }
      showOptions.value = true
      resetTemporarySelection()
      loadInitalList()
      nextTick(() => {
        setFocusOnInput()
      })
    }

    /**
     * @description Removes an option from the list of selected options.
     * @param index index of item to be removed.
     */
    function removeItem (index) {
      optionsSelected.value.splice(index, 1)
      setPlaceholder()
      emit('unsavedChanges')
      emit('update:modelValue', optionsSelected.value)
    }

    /**
     * @description Resets input with its default value.
     */
    function resetInput () {
      optionSelected.id = null
      optionSelected.value = null
    }

    /**
     * @description Sets the index to track selected option at the begining.
     */
    function resetTemporarySelection () {
      temporarySelectionIndex.value = 0
    }

    /**
     * @description Sets an option as selected, if component allows multiple selections, option is
     * added to array of selected options.
     * @param selectedOption option selected by the user.
     * @param valueChanged flag to indicate that a new option have been added
     */
    function selectOption (selectedOption, valueChanged) {
      markInputAsValidOption()
      addOptionToSelectedOptions(selectedOption)
      resetInput()
      closeOptions(false)
      if (valueChanged) {
        emit('unsavedChanges')
        emit('update:modelValue', optionsSelected.value)
      }
    }

    /**
     * @description Sets value passed via v-model from parent.
     */
    function setDefaultValue () {
      if (props.modelValue) {
        if (typeof props.modelValue === 'object') {
          props.modelValue.forEach(selectedValue => selectOption(Object.assign({}, selectedValue), false))
        }
      }
    }

    /**
     * @description Sets the focus of the page in the input, only for mobile devices, to avoid
     * virtual keyboard covers the input area.
     */
    function setFocusOnInput () {
      if (store.state.isMobile) {
        dropdown.value.parentElement.scrollIntoView()
      }
    }

    /**
     * @description Adds the value enter by user to the list of selected options, even when that
     * value is not in the list of options.
     */
    function setValueEnterByUser () {
      if (props.userValuesAllowed && optionSelected.value) {
        selectOption({ id: null, value: optionSelected.value }, true)
      }
    }

    /**
     * @description Sets value selected at currren position of temporary index.
     */
    function setValueSelectedByUser () {
      if (temporarySelectionIndex.value > -1 && showOptionList.value) {
        selectOption(optionsToShow.value[temporarySelectionIndex.value], true)
      } else {
        setValueEnterByUser()
      }
    }

    /**
     * @description Moves scroll of the list to show the current item selected.
     */
    function scrollToOption () {
      const topOfListContainer = listContainer.value.getBoundingClientRect().top
      const topOfSelectedOption = listContainer.value.getElementsByClassName('selected')[0].getBoundingClientRect().top
      const topOfList = list.value.getBoundingClientRect().top

      if (topOfSelectedOption - topOfListContainer > 214) {
        listContainer.value.scrollTo(0, topOfSelectedOption - topOfList)
      }

      if (topOfListContainer - topOfSelectedOption > 0) {
        listContainer.value.scrollTo(0, listContainer.value.scrollTop - (topOfListContainer - topOfSelectedOption))
      }
    }

    /**
     * @description Updates value of input model.
     * @param {Event} event of input change fired by user.
     */
    function updateInput (event) {
      const regexToSplitInput = /[\s,;]+/
      if (props.userValuesAllowed && event.target.value.search(regexToSplitInput) >= 0) {
        const words = event.target.value.split(regexToSplitInput).filter(word => word && word !== ' ')
        words.forEach(word => {
          selectOption({ id: null, value: word }, true)
        })
      } else {
        optionSelected.value = event.target.value
      }
    }

    /**
     * @description Show or hide placeholder input
     */
    function setPlaceholder () {
      placeholder.value = optionsSelected.value.length === props.options.length ? '' : 'Start typing...'
    }

    /**
     * @description Filters selected options to only show those ailable on the list of selecionable options.
     */
    function validateOptions () {
      const tempOptionsSelected = []
      props.options.forEach(option => {
        const index = optionsSelected.value.findIndex(optionSelected => option.id === optionSelected.id)
        if (index !== -1) {
          tempOptionsSelected.push(Object.assign({}, optionsSelected.value[index]))
          optionsSelected.value.splice(index, 1)
        }
      })
      optionsSelected.value = tempOptionsSelected
      placeholder.value = 'Start typing...'
      emit('update:modelValue', optionsSelected.value)
    }

    return {
      store,
      showOptionList,
      multipleInput,
      dropdown,
      listContainer,
      list,
      initialValueLoaded,
      keyUpHandler,
      openOptions,
      selectOption,
      updateInput,
      optionSelected,
      optionsSelected,
      placeholder,
      closeOptions,
      optionsToShow,
      temporarySelectionIndex,
      removeItem,
      focusEnabled
    }
  }
}
</script>
